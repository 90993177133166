import { Box, Avatar, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import moment from "moment/moment";
const Footer = () => {
  const currentYear = moment().format("YYYY");

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="2rem"
      sx={{ bgcolor: "#000", py: "2rem" }}
    >
      <Link to="/">
        <Avatar
          src="/assets/img/logo.svg"
          alt="logo"
          sx={{
            width: { xs: "8rem", md: "6rem" },
            height: { xs: "8rem", md: "6rem" },
          }}
        />
      </Link>
      <Stack
        direction={{ xs: "column", md: "row" }}
        gap={{ xs: "1rem", md: "2.7rem" }}
      >
        <Link to="/">
          <Button
            className="btn"
            sx={{
              color: "#ffff",
              textTransform: "capitalize",
              fontSize: "1rem",
              "&:hover": { color: "#00ABAB" },
            }}
          >
            Home
          </Button>
        </Link>

        <Link to="/about">
          <Button
            sx={{
              color: "#ffff",
              textTransform: "capitalize",
              fontSize: "1rem",
              "&:hover": { color: "#00ABAB" },
            }}
          >
            About
          </Button>
        </Link>
        <Link to="/products">
          <Button
            sx={{
              color: "#ffff",
              textTransform: "capitalize",
              fontSize: "1rem",
              "&:hover": { color: "#00ABAB" },
            }}
          >
            Products
          </Button>
        </Link>
        <Link to="/Partners">
          <Button
            sx={{
              color: "#ffff",
              textTransform: "capitalize",
              fontSize: "1rem",
              "&:hover": { color: "#00ABAB" },
            }}
          >
            Partners
          </Button>
        </Link>
        <Link to="/faqs">
          <Button
            sx={{
              color: "#ffff",
              textTransform: "capitalize",
              fontSize: "1rem",
              "&:hover": { color: "#00ABAB" },
            }}
          >
            FAQs
          </Button>
        </Link>
        <Link to="/guidelines">
          <Button
            sx={{
              color: "#ffff",
              textTransform: "capitalize",
              fontSize: "1rem",
              "&:hover": { color: "#00ABAB" },
            }}
          >
            Guidelines
          </Button>
        </Link>
        <Link to="/blogs">
          <Button
            sx={{
              color: "#ffff",
              textTransform: "capitalize",
              fontSize: "1rem",
              "&:hover": { color: "#00ABAB" },
            }}
          >
            Blogs
          </Button>
        </Link>
      </Stack>

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap="2rem"
      >
        {/* <Link to="/">
          <img
            src="./assets/img/twitter.svg"
            alt="twitter"
            style={{ width: "2.9rem", height: "2.2rem" }}
          />
        </Link> */}

        <Link to="https://www.facebook.com/profile.php?id=100091456360006">
          <FacebookRoundedIcon style={{ color: "#00ABAB", fontSize: "3rem" }} />
        </Link>

        <Link to="https://www.instagram.com/noonancaddie/">
          <InstagramIcon style={{ color: "#00ABAB", fontSize: "3rem" }} />
        </Link>
      </Stack>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ borderTop: "1px solid #B3B3B3" }}
      >
        <Link to="/privacy-policy" className="privacy_policy_link">
          Privacy Policy
        </Link>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#fff",
            fontSize: ".8rem",
            mt: ".6rem",
            textAlign: "center",
          }}
        >
          © Copyright {currentYear}, All Rights Reserved by Noonan.
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            fontSize: ".8rem",

            mt: ".6rem",
            textAlign: "center",
          }}
        >
          Developed by&nbsp;
          <Link
            to="https://musketeerstech.com"
            style={{
              color: "#00abab",
              fontWeight: "bold",
            }}
          >
            Musketeers Tech Inc.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;

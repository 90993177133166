import React, { useEffect, useState } from "react";
import "./blogs.css";
import { Box, Container, Typography, Grid } from "@mui/material";
import LatestBlogs from "./LatestBlogs";
import ApplyForm from "../about/ApplyForm";
import BlogCarousel from "./BlogCarousel";
import { fetchBlogs } from "../apis/fetchBlogData";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);

  useEffect(() => {
    const getBlogs = async () => {
      setLoading(true);
      const fetchedBlogs = await fetchBlogs();
      fetchedBlogs.length > 0
        ? setLoading(false)
        : fetchedBlogs.length < 1 && setShowNotFound(true);
      setLoading(false);
      setBlogs(fetchedBlogs);
    };
    getBlogs();
  }, []);

  const sortedBlogs = blogs?.sort(
    (a, b) => moment(b?._createdAt) - moment(a?._createdAt)
  );

  if (loading) {
    return (
      <Grid container py={{ md: "8rem", xs: "4rem" }}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
         
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center"  }}>
            <CircularProgress size="large" />
          </Box>
        </Grid>
      </Grid>
    );
  }
  if (showNotFound) {
    return (
      <Grid container py={{ md: "8rem", xs: "8rem" }}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Typography className="latest_blog" textAlign={"center"}>
            No Blogs Found!
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box mt="4rem">
      <BlogCarousel blogs={sortedBlogs} />
      <Container>
        <LatestBlogs blogs={sortedBlogs} />
      </Container>
      <ApplyForm />
    </Box>
  );
};

export default Blogs;

import ContactForm from "../common/ContactForm";
import { Box, Grid } from "@mui/material";

const ApplyForm = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: "2rem", mb: { md: "4rem" }, width: { md: "80%" } }}
      >
        <Grid item xs={12} md={5.8} sx={{ px: "1rem" }}>
          <h3 className="product_form_h3">
            Apply to join the Early Adopter Program
          </h3>

          <ol className="product_ol">
            <li className="product_li">
              Be among the first to gain access to the app and gain an edge on
              your competition.
            </li>

            <li className="product_li">
              Receive special discounts & promotions.
            </li>

            <li className="product_li">
              Stay updated on our development progress
            </li>
          </ol>
        </Grid>
        <Grid
          item
          xs={12}
          md={5.8}
          // className="form-bg-m"
          sx={{
            bgcolor: { xs: "#e6f7f7", md: "transparent" },
            p: { xs: "2.5rem 1rem", md: "1rem" },
          }}
        >
          <ContactForm />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ApplyForm;

import { Grid, Typography } from "@mui/material";
import React from "react";

const PrivacyHeader = () => {
  return (
    <Grid
      container
      sx={{ mt: { xs: "5rem", md: "10rem" } }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      width={{ xs: "90%", md: "80%" }}
    >
      <Grid item xs={12} md={7}>
        <Typography className="privacy_heading">Privacy Policy</Typography>
        <Typography className="privacy_detail">
          Thank you for choosing Noonan, an AI-based golf caddie app that helps
          you improve your game by providing club recommendations and aiming
          assistance. Your privacy is important to us, and we are committed to
          protecting your personal information. This Privacy Policy outlines how
          we collect, use, disclose, and safeguard your data when you use the
          Noonan app. By using our services, you agree to the practices
          described in this policy.
        </Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <img
          src="./assets/img/privacy/privacy.svg"
          alt="privacy"
          className="privacy_img"
        />
      </Grid>
    </Grid>
  );
};

export default PrivacyHeader;

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	);
});

export default function SimulatorModal({
	open,
	handleClose,
	title,
	content,
	dialogActions,
}) {
	return (
		<Dialog
			TransitionComponent={Transition}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",

				"& .MuiDialog-paper": {
					maxWidth: { xs: "90%", md: "80%" },
					borderRadius: "1.5rem",
					padding: { xs: ".6rem", md: "3.75rem 4.375rem" },
				},
			}}
			open={open}
			onClose={handleClose}
		>
			<DialogContent>{content}</DialogContent>
			{dialogActions}
		</Dialog>
	);
}

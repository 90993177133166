import React from 'react'
import Banner from './Banner'
import Accordian from './Accordian'
import ApplyForm from '../about/ApplyForm'

const Faqs = () => {
  return (
    <>
    <Banner />
    <Accordian />
    <ApplyForm />
      
    </>
  )
}

export default Faqs

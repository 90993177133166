import { useEffect } from "react";
import DispersionPattern from "./DispersionPattern";
import NoonanWork from "./NoonanWork";
import NoonanTracking from "./NoonanTracking";
import ApplyForm from "./ApplyForm";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

const Products = () => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const scrollToTarget = queryParams.get("scrollTo");

    if (scrollToTarget) {
      const element = document.getElementById(scrollToTarget);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [location.search]);
  return (
    <>
      <DispersionPattern />
      <NoonanWork />
      <NoonanTracking />
      <Box id="contact_form">
        <ApplyForm />
      </Box>
    </>
  );
};

export default Products;

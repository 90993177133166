import { Grid, Typography, Avatar, Box, List, ListItem } from "@mui/material";

const CustomerBenefit = () => {
  return (
    <Box
      sx={{
        p: { xs: "1rem", md: "2rem 0rem 2rem 6rem" },
        mt: { xs: "5rem", md: "0rem" },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "center", md: "flex-start" },
            flexDirection: "column",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "1.5rem", lg: "1.9rem" },
              fontWeight: "bold",
              color: { xs: "#00ABAB", md: "#000" },
            }}
          >
            How do my Customers Benefit?
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "1rem", lg: "1.3rem" },
              color: '#565656',
              lineHeight: "1.5",
              mt: "1rem",
            }}
          >
            Your customers are generating invaluable data about how they hit
            their clubs each time they frequent your business. Unfortunately,
            few actually know how to bring those insights onto the course.
            Noonan uses golf simulator data to run our Scatter Shot Analytics,
            which analyzes players' club dispersion patterns & playing
            conditions to determine which shot has the highest odds of success.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "1.5", lg: "1.9rem" },
              fontWeight: "bold",
              mt: "1rem",
              color: { xs: "#00ABAB", md: "#000" },
            }}
          >
            The Result?
          </Typography>
          <List className="list">
            <ListItem className="list_item">
              <Typography variant="body1" className="bullet_text">
                &bull; Less penalties
              </Typography>
            </ListItem>
            <ListItem className="list_item">
              <Typography variant="body1" className="bullet_text">
                &bull; Lower scores!
              </Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: { xs: "3rem", md: "0rem" },
          }}
        >
          <Avatar
            variant="square"
            src="./assets/img/partners/hockey.svg"
            alt="partner_banner"
            sx={{ width: "100%", height: "100%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerBenefit;

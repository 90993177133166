import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const NoonanWork = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div style={{ background: "rgba(0, 171, 171, 0.04)" }}>
        <div className="container mt-sec">
          <h2 className="product-head pt-5">How Noonan Works</h2>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            className="noonan-w-slider pt-0 mob-none"
          >
            <Carousel.Item>
              <div className="row">
                <div className="col-lg-7">
                  <div className="noonan-work-text">
                    <h3 style={{ lineHeight: "56px" }}>1. Learns your game</h3>
                    <p>
                      Golf simulators are the most accurate way to measure how
                      players hit a shot.
                    </p>
                    <p>
                      Over 5 million golfers already use golf simulators, and we
                      use the data being generated to understand how a player
                      hits each club in their bag.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 text-end">
                  <img
                    src="/assets/img/products/learn_game.webp"
                    alt="phone-img"
                    style={{ width: "40%" }}
                  />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row">
                <div className="col-lg-5">
                  <img
                    src="/assets/img/products/playing_condition.webp"
                    alt="phone-img"
                    style={{ width: "40%" }}
                  />
                </div>
                <div className="col-lg-7">
                  <div className="noonan-work-text">
                    <h3 style={{ lineHeight: "56px" }}>
                      2. Analyzes Playing Conditions
                    </h3>
                    <p>
                      Just like a real-life caddie, Noonan analyzes the playing
                      conditions for each shot. Where other apps stop at weather
                      & elevation, Noonan factors in proximity to hazards into
                      our decision making.
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row">
                <div className="col-lg-7">
                  <div className="noonan-work-text">
                    <h3 style={{ lineHeight: "56px" }}>
                      3. Displays Club & Aim Recommendation
                    </h3>
                    <p>
                      'Play the percentages' with ease. Noonan automatically
                      calculates the mathematically correct club & aim point for
                      each shot in real-time.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 text-end">
                  <img
                    src="/assets/img/products/clubs_aim.webp"
                    alt="phone-img"
                    style={{ width: "40%" }}
                  />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
          <div className="desktop-none">
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              className="noonan-w-slider pt-0"
            >
              <Carousel.Item>
                <div className="noonan-work-text">
                  <h3>Learns your game</h3>
                  <p>
                    Golf simulators are the most accurate way to measure how
                    players hit a shot.
                  </p>
                  <p>
                    Over 5 million golfers already use golf simulators, and we
                    use the data being generated to understand how a player
                    hits each club in their bag.
                  </p>
                </div>
                <div className="text-center">
                  <img
                    src="/assets/img/products/learn_game.webp"
                    alt="phone-img"
                    style={{ width: "40%" }}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="noonan-work-text">
                  <h3>Analyzes Playing Conditions</h3>
                  <p>
                    Just like a real-life caddie, Noonan analyzes the playing
                    conditions for each shot. Where other apps stop at weather &
                    elevation, Noonan factors in proximity to hazards into our
                    decision making.
                  </p>
                </div>
                <div className="text-center">
                  <img
                    src="/assets/img/products/playing_condition.webp"
                    alt="phone-img"
                    style={{ width: "40%" }}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="noonan-work-text">
                  <h3 style={{ lineHeight: "56px" }}>
                    Displays Club & Aim Recommendation
                  </h3>
                  <p>
                    'Play the percentages' with ease. Noonan automatically
                    calculates the mathematically correct club & aim point for
                    each shot in real-time.
                  </p>
                </div>

                <div className="text-center">
                  <img
                    src="/assets/img/products/clubs_aim.webp"
                    alt="phone-img"
                    style={{ width: "40%" }}
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoonanWork;

import React, { useRef } from "react";

const KeyFeature = () => {
  const keyMobBoxRef = useRef(null);
  const keyMobBoxRef4 = useRef(null);
  const keyMobBoxRef3 = useRef(null);
  const keyMobBoxRef2 = useRef(null);

  const scrollToKeyMobBox = () => {
    keyMobBoxRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const scrollToKeyMobBox4 = () => {
    keyMobBoxRef4.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const scrollToKeyMobBox2 = () => {
    keyMobBoxRef2.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const scrollToKeyMobBox3 = () => {
    keyMobBoxRef3.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  return (
    <>
      <div className="container mt-5 mob-none">
        <img
          src="/assets/img/home/key-shap.svg"
          alt="key-shap"
          className="key-shap"
        />

        <h2 className="intro-head">Key Features</h2>
        <div className="key-row">
          <div className="">
            <div className="key-text">
              <h3>Lie Detector AI (Coming Soon)</h3>
              <p>Recognize how your ball will react coming out of the grass</p>
            </div>
            <div className="key-text mt-key">
              <h3>Club & Aim Recommendations</h3>
              <p>
                Avoid penalties & mental mistakes by letting Noonan optimize
                your shot selection with Scattershot Analytics <sup>TM</sup>
              </p>
            </div>
          </div>
          <div className="">
            <div className="mobile-img text-center">
              <img src="/assets/img/home/mobile-img.webp" alt="mobile-img" />
            </div>
          </div>
          <div className="">
            <div className="key-text text-start mtr-key">
              <h3>Dispersion Patterns</h3>
              <p>
                Visually see where 95% of your shots will land for each club
              </p>
            </div>
            <div></div>
            <div className="key-text text-start mtr-key-right">
              <h3>‘Plays Like’ Distances</h3>
              <p>
                Realtime distance adjustments for Wind, Temperature and Terrain
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* mobile screen */}
      <div className="desktop-none">
        <div className="container mt-sec">
          <h2 className="intro-head">Key Features</h2>
          <div className="mobile-scr-img text-center">
            <img
              src="/assets/img/home/key_1.png"
              alt="key1-img"
              className="key1-img"
              onClick={scrollToKeyMobBox}
            />
            <img
              src="/assets/img/home/key_3.png"
              alt="key3-img"
              className="key3-img"
              onClick={scrollToKeyMobBox3}
            />
            <img src="/assets/img/home/m_home_img.webp" alt="mobile-img" style={{marginBottom:"1rem"}} />
            <img
              src="/assets/img/home/key_2.png"
              alt="key2-img"
              className="key2-img"
              onClick={scrollToKeyMobBox2}
            />
            <img
              src="/assets/img/home/key_4.png"
              alt="key4-img"
              className="key4-img"
              onClick={scrollToKeyMobBox4}
            />
          </div>
          <div ref={keyMobBoxRef} className="key-mob-box">
            <h3>Lie Detector AI - Coming Soon</h3>
            <p>Recognize how your ball will react coming out of the grass</p>
          </div>
          <div ref={keyMobBoxRef2} className="key-mob-box">
            <h3>Dispersion Patterns</h3>
            <p>Visually see where 95% of your shots will land for each club</p>
          </div>
          <div ref={keyMobBoxRef3} className="key-mob-box">
            <h3>Club & Aim Recommendation</h3>
            <p>
              Avoid penalties & mental mistakes by letting Noonan optimize your
              shot selection with Scattershot Analytics ™
            </p>
          </div>
          <div ref={keyMobBoxRef4} className="key-mob-box">
            <h3>‘Plays Like’ Distance</h3>
            <p>
              Realtime distance adjustments for Wind, Temperature and Terrain
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeyFeature;

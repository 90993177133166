import { Typography, Box, Card, Stack } from "@mui/material";

import PackageCard from "./PackageCard";

const CaseStudy = () => {
  const packageData = [
    // {
    //   packageTitle: "New Sales Package #1- Noonan Caddie Program",
    //   subscriptionPrice: "($30)",
    //   subscriptionCount: "($150)",
    //   subscriptionText: "Noonan Subscription",
    //   simulatorSessions: "(3) Simulator sessions to input shot data",
    //   price: "$380,000",
    //   priceDetail: "Net Revenue/year",
    // },

    // {
    //   packageTitle: "Add-on Sale - Noonan Caddie",
    //   subscriptionPrice: "($30)",
    //   subscriptionCount: "($150)",
    //   subscriptionText: "Noonan Subscription",
    //   simulatorSessions: "(3) Simulator sessions to input shot data",
    //   price: "$380,000",
    //   priceDetail: "Net Revenue/year",
    // },
    {
      packageTitle: "Add-on Sale - Noonan Caddie",
      subscriptionPrice: "($30)",
      subscriptionCount: "($150)",
      subscriptionText: "Noonan Subscription",
      simulatorSessions: "(3) Simulator sessions to input shot data",
      price: "$380,000",
      priceDetail: "Net Revenue/year",
    },
  ];

  return (
    <Box className="caseStudy_main" gap={3}>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "1.5rem", md: "2.2rem" },
          color: "#111111",
        }}
      >
        Case Study
      </Typography>
      <Card className="case_study_card">
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h4" fontSize="3rem" fontWeight="600">
            4
          </Typography>
          <Typography variant="subtitle1" className="typo_height">
            Bay
            <br /> Facility
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h4" className="typo_weight">
            2000
          </Typography>
          <Typography variant="subtitle1" className="typo_height">
            Customers <br />
            Annually
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h4" className="typo_weight">
            $50
          </Typography>
          <Typography variant="subtitle1" className="typo_height">
            Hourly
            <br /> Rate
          </Typography>
        </Stack>
      </Card>

      <Typography
        sx={{
          fontSize: "1.6rem",
          width: { xs: "100%", md: "70%" },
          textAlign: "center",
          color: "#565656",
          fontWeight: "500",
          fontFamily: "Poppins",
          fontStyle: "normal",
        }}
      >
        Increase revenue by offering new packages to customers built around
        Noonan and complimentary services.
      </Typography>
      <Box
        width={{ md: "90%", lg: "60%" }}
        display="flex"
        gap="1rem"
        flexDirection={{ xs: "column-reverse", md: "column" }}
        sx={{ padding: "1rem" }}
      >
        {packageData.map((packageValues, index) => (
          <PackageCard key={index} {...packageValues} />
        ))}
      </Box>
    </Box>
  );
};

export default CaseStudy;

import { createClient } from "@sanity/client";
const projectId = process.env.REACT_APP_SANITY_PROJECT_ID;
const dataset = process.env.REACT_APP_SANITY_DATASET;
const apiVersion = process.env.REACT_APP_SANITY_API_VERSION;
const useCdn = process.env.REACT_APP_SANITY_USE_CDN === "true";
const token = process.env.REACT_APP_SANITY_TOKEN;

if (!projectId) {
	throw new Error("Missing REACT_APP_SANITY_PROJECT_ID");
}
if (!dataset) {
	throw new Error("Missing REACT_APP_SANITY_DATASET");
}
if (!apiVersion) {
	throw new Error("Missing REACT_APP_SANITY_API_VERSION");
}
if (!token) {
	throw new Error("Missing REACT_APP_SANITY_TOKEN");
}

const sanityClient = createClient({
	projectId,
	dataset,
	apiVersion,
	useCdn,
	token,
});

export default sanityClient;

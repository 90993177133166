// // +1 (555) 555-5555
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, Button, Grid, Snackbar, CircularProgress } from "@mui/material";
import emailjs from "@emailjs/browser";

const initialValues = {
	firstName: "",
	lastName: "",
	email: "",
	phoneNumber: "",
	message: "",
};

const validationSchema = Yup.object().shape({
	firstName: Yup.string()
		.required("* Required")
		.matches(/^[A-Za-z .]+$/, "only letters")
		.min(2, "at least 2 character")
		.max(25, "at most 25 characters"),
	lastName: Yup.string()
		.required("* Required")
		.matches(/^[A-Za-z .]+$/, "only letters")
		.min(2, "at least 2 character")
		.max(25, "at most 25 characters"),
	email: Yup.string().email("Invalid email").required("* Required"),
	phoneNumber: Yup.string()
		.matches(
			/^\+?1?\s?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})[-.\s]?[2-9][0-9]{2}[-.\s]?[0-9]{4}$/,
			"Invalid number format"
		)
		.min(12, "at least 12 digits")
		.max(12, "maximum 12 digits")
		.required("* Required"),
	message: Yup.string()
		.required("* Required")
		.min(5, "at least 5 character")
		.max(500, "at most 500 characters"),
});

const FormComponent = () => {
	const [loader, setLoader] = useState(false);
	const [message, setMessage] = useState(false);

	const serviceId = process.env.REACT_APP_SERVICE_ID;
	const templateId = process.env.REACT_APP_TEMPLATE_ID;
	const public_key = process.env.REACT_APP_PUBLIC_KEY;

	const sendEmail = async (values) => {
		setLoader(true);
		const templateParams = {
			first_name: values.firstName,
			last_name: values.lastName,
			phone_number: values.phoneNumber,
			email: values.email,
			message: values.message,
		};

		try {
			const result = await emailjs.send(
				serviceId,
				templateId,
				templateParams,
				public_key
			);

			if (result) {
				setLoader(false);
				setMessage(true);
			}
		} catch (error) {
			alert(error.text);
		}
	};

	const handleLoaderClose = () => {
		setLoader(false);
	};
	const handleSnackClose = () => {
		setMessage(false);
	};

	const handleSubmit = (values, action) => {
		sendEmail(values);
		action.resetForm();
	};

	const handlePhoneNumberChange = (event, setFieldValue) => {
		const phoneNumber = event.target.value.replace(/[^\d+]/g, "");
		setFieldValue("phoneNumber", phoneNumber);
	};
	return (
		<>
			<div
				id="lets-talk"
				className="form-heading"
			>
				<h2 >Let’s Talk</h2>
				<p>Our friendly team would love to hear from you.</p>
			</div>

			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting, setFieldValue }) => (
					<Form>
						<Grid
							container
							rowGap=".8rem"
							justifyContent="space-between"
						>
							<Grid
								item
								xs={5.9}
							>
								<label className="faq_label">First Name</label>
								<Field
									name="firstName"
									fullwidth
									className="border-radius form-control py-3 px-3"
									placeholder="First name"
								/>
								<ErrorMessage
									name="firstName"
									component="div"
									className="error-message text-danger"
								/>
							</Grid>

							<Grid
								item
								xs={5.9}
							>
								<label className="faq_label">Last Name</label>
								<Field
									name="lastName"
									className="border-radius form-control py-3 px-3"
									placeholder="Last name"
									fullwidth
								/>
								<ErrorMessage
									name="lastName"
									component="div"
									className="error-message text-danger"
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<label className="faq_label">Email</label>
								<Field
									name="email"
									className="border-radius form-control py-3 px-3"
									placeholder="Email"
									fullwidth
								/>
								<ErrorMessage
									name="email"
									component="div"
									className="error-message text-danger"
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<label className="faq_label">Phone number</label>
								<Field
									name="phoneNumber"
									className="border-radius form-control py-3 px-3"
									placeholder="+1 (555) 000-0000"
									fullwidth
									onChange={(event) =>
										handlePhoneNumberChange(event, setFieldValue)
									}
								/>
								<ErrorMessage
									name="phoneNumber"
									component="div"
									className="error-message text-danger"
								/>
							</Grid>
							<label className="faq_label">Message</label>
							<Grid
								item
								xs={12}
							>
								<Field
									as="textarea"
									name="message"
									className="form-control _textarea"
									style={{
										borderRadius: "20px",
										minHeight: "100px",
										maxHeight: "200px",
									}}
									rows={4}
								/>
								<ErrorMessage
									name="message"
									component="div"
									className="error-message text-danger"
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<Button
									type="submit"
									className="w-100 border-radius bg-btn"
								>
									Send message
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>

			<Snackbar
				open={loader}
				onClose={handleLoaderClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Button sx={{ pointerEvents: "none" }}>
					<CircularProgress />
				</Button>
			</Snackbar>
			<Snackbar
				open={message}
				autoHideDuration={2000}
				onClose={handleSnackClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Alert
					elevation={6}
					variant="filled"
				>
					Message sent successfully!
				</Alert>
			</Snackbar>
		</>
	);
};

export default FormComponent;

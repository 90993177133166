import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./component/home";
import Products from "./component/products";
import Partners from "./component/partners";
import About from "./component/about";
import Header from "./component/common/Header";
import Footer from "./component/common/Footer";
import { Routes, Route } from "react-router-dom";
import Faqs from "./component/Faqs.js";
import ScrollToTop from "./component/common/ScrollToTop";
import PrivacyPolicy from "./component/privacypolicy";
import Guidelines from "./component/guidelines";
import ErrorPage from "./component/error";
import Blogs from "./component/blogs";
import BlogDetail from "./component/blogs/BlogDetail.js";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  return (
    <>
      <HelmetProvider>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/about" element={<About />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:blogRoute" element={<BlogDetail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/guidelines" element={<Guidelines />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
	    </HelmetProvider>
    </>
  );
};

export default App;

import { Typography, Card, Stack, Grid } from "@mui/material";

const Benefits = () => {
  return (
    <Grid
      container
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: { xs: "5rem 1rem", md: " 6rem" },
        bgcolor: "rgba(0, 171, 171, 0.04)",
      }}
    >
      <Grid item xs={12} md={6}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: { xs: "#00ABAB", md: "#000" },
            mb: { xs: "3rem", md: "0rem" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          What's in it for me?
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack>
          <Card className="benefit_card">
            <img

              src="./assets/img/partners/progress.svg"
              alt="progress"
              className="benefit_avatar"
            />
            <Typography className="benefit_text">Increased Revenue</Typography>
          </Card>
          <Card className="benefit_card">
            <img

              src="./assets/img/partners/hand.svg"
              alt="progress"
              className="benefit_avatar"
            />
            <Typography className="benefit_text">Customer Loyalty</Typography>
          </Card>

          <Card className="benefit_card">
            <img

              src="./assets/img/partners/eye.svg"
              alt="Visibility"
              className="benefit_avatar"
            />
            <Typography className="benefit_text">Greater Visibility</Typography>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Benefits;

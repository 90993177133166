import React from "react";

const IntroNoonan = () => {
  return (
    <div className="container mt-sec mob-intro-cont">
      <h2 className="intro-head">Introducing Noonan, Your Tour-Level Caddie</h2>
      <div className="row">
        <div className="col-lg-4 col-md-4">
          <div className="intro-box">
            <img
              src="/assets/img/home/intro-img1.svg"
              alt="intro-img1"
              className="img-fluid"
            />
            <h3>Bad Target</h3>
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="intro-box">
            <img
              src="/assets/img/home/intro-img2.svg"
              alt="intro-img2"
              className="img-fluid"
            />
            <h3>Noonan Target</h3>
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="intro-paragraph">
            <p>
              The first mobile app that makes decisions just like a Tour-Level
              Caddie - recommending the 'High-Percentage Shot' for each
              situation.
            </p>
            <p>
              Noonan’s proprietary Scatter Shot Analytics leverages the power of
              shot dispersion patterns to predict & display the area in which
              95%+ of your shots will land for each club and automatically
              selects the target with the highest odds of success.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroNoonan;

import React, { createContext, useState } from 'react';

const SanityRecorIdContext = createContext();

const SanityRecorIdProvider = ({ children }) => {
  const [id, setId] = useState(null);

  return (
    <SanityRecorIdContext.Provider value={{ id, setId }}>
      {children}
    </SanityRecorIdContext.Provider>
  );
};

export { SanityRecorIdContext, SanityRecorIdProvider };

import {
	Grid,
	Typography,
	Box,
	Button,
	useMediaQuery,
	DialogActions,
} from "@mui/material";
import "../guidelines/guidelines.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SimulatorModal from "../simulatormodal/SimulatorModal";
import { useState } from "react";

const SimulatorStep2 = ({ step, setStep, handlePrev }) => {
	const isLargeScreen = useMediaQuery("(min-width: 900px)");
	const isSmallScreen = useMediaQuery("(max-width: 600px)");
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setStep(step + 1);
	};

	const btn1 = (
		<Button
			startIcon={isLargeScreen ? <ArrowBackIcon /> : null}
			endIcon={isLargeScreen ? <ArrowForwardIcon /> : null}
			className="guidelines_btn_next"
			disableRipple
			onClick={() => setStep(3)}
		>
			Continue
		</Button>
	);

	const dialogActions = (
		<DialogActions className="step2ModalbtnAlign">{btn1}</DialogActions>
	);

	const dialogContent2 = (
		<Grid
			container
			alignItems={{ xs: "center", md: "flex-start" }}
			spacing={1}
		>
			<Grid
				item
				xs={12}
				order={{ xs: 1, md: 0 }}
			>
				<Typography
					className="quick_start"
					mb={{ xs: ".6rem", md: "2.5rem" }}
					mt={{ xs: "1.25rem", md: "0" }}
				>
					Helpful Tips
				</Typography>
			</Grid>
			<Grid
				item
				md={4}
				xs={12}
				display="flex"
				justifyContent={{ xs: "center", md: "flex-start" }}
				order={{ xs: 0, md: 1 }}
			>
				<Box>
					<img
						src="/assets/img/guidelines/healthtips.webp"
						alt="guidelines_img"
						className="heathtipsimg"
					/>
				</Box>
			</Grid>

			<Grid
				item
				md={8}
				xs={12}
				order={2}
			>
				<Typography
					className="hit_heading"
					mb=".62rem"
				>
					Avoid Fatigue
				</Typography>
				<Typography className="hit_para">
					Hitting 10 shots with each club consecutively is a lot of work!. Don’t
					be afraid to take breaks between clubs, or even split up your data
					capture sessions (i.e. woods, irons, wedges) to ensure that you are
					providing the Noonan platform with the most accurate data possible.
				</Typography>
			</Grid>
		</Grid>
	);

	return (
		<Box className="container">
			{isSmallScreen ? null : (
				<Button
					startIcon={<ArrowBackIcon />}
					className="backBtn"
					onClick={() => handlePrev(setStep - 1)}
				>
					Back
				</Button>
			)}
			<Box sx={{ display: "flex", gap: 2 }}>
				<Box
					sx={{ display: { md: "none", sm: "none", cursor: "pointer" } }}
					onClick={() => handlePrev(setStep - 1)}
				>
					<ArrowBackIcon style={{ color: "#00abab" }} />
				</Box>
				<Box>
					<Typography
						className="getting_started"
						mb="1.5rem"
						sx={{ display: { md: "block" } }}
					>
						Getting Started
					</Typography>
				</Box>
			</Box>

			<Typography
				className="quick_start"
				mb="3.4rem"
			>
				Step 2 - <span> Swing Away!</span>
			</Typography>
			<Grid
				container
				sx={{ display: "flex", gap: { xs: "3rem", md: "0rem" } }}
			>
				<Grid
					item
					xs={12}
					md={12}
				>
					<Typography
						className="hit_heading"
						mb="1rem"
					>
						Swing Away!
					</Typography>
					<Typography
						mb="3.44rem"
						className="hit_para"
					>
						Hit at least 10 shots with each club. Make sure to select which club
						you’re hitting in the simulator before hitting.
					</Typography>
					<Box className="outerborder">
						<Typography
							className="guidelines_step2_reminder"
							mb="0.25rem"
						>
							Important Reminder
						</Typography>
						<Typography className="hit_para">
							Make sure to change your club selection in the simulator when you
							change clubs in real life. Otherwise, your shots will register for
							the wrong club!
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Button
				startIcon={isLargeScreen ? <ArrowBackIcon /> : null}
				endIcon={isLargeScreen ? <ArrowForwardIcon /> : null}
				className="guidelines_btn_next"
				mb="3.44rem"
				disableRipple
				onClick={handleClickOpen}
			>
				Next
			</Button>
			<SimulatorModal
				content={dialogContent2}
				open={open}
				handleClose={handleClose}
				dialogActions={dialogActions}
			/>
		</Box>
	);
};

export default SimulatorStep2;

import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Box } from "@mui/material";
function RangeFinder () {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className="mob-flex-dirction">
        <div className="container fact-container">
          <Box className="fact-img mob-none">
            <div className="row">
              <div className="col-lg-3">
                <div className="text-end">
                  <img
                    src="/assets/img/home/player.svg"
                    alt="player-img"
                    className="player-img"
                  />
                </div>
              </div>
              <div className="col-lg-9">
                <Carousel
                  activeIndex={index}
                  onSelect={handleSelect}
                  className="slider-fact"
                >
                  <Carousel.Item>
                    <h2>Facts</h2>
                    <p>
                      A golfer that shoots 79 makes only 1 more birdie per round
                      than a golfer that shoots 95
                    </p>
                  </Carousel.Item>
                  <Carousel.Item>
                    <h2>Facts</h2>
                    <p>
                      The key to shooting lower scores is to Make Less Mistakes…not More Birdies
                    </p>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </Box>
          <Box className="desktop-none">
            <Box>
              <div className="text-center">
                <img
                  src="/assets/img/home/player.svg"
                  alt="player-img"
                  className="player-img"
                />
              </div>
              <Box className="fact-img">
                <Carousel
                  activeIndex={index}
                  onSelect={handleSelect}
                  className="slider-fact"
                >
                  <Carousel.Item>
                    <h2>Facts</h2>
                    <p>
                      A golfer that shoots 79 makes only 1 more birdie per round
                      than a golfer that shoots 95
                    </p>
                  </Carousel.Item>
                  <Carousel.Item>
                    <h2>Facts</h2>
                    <p>
                      The key to shooting lower scores is to Make Less Mistakes…not More Birdies
                    </p>
                  </Carousel.Item>
                </Carousel>
              </Box>
            </Box>
          </Box>
        </div>

        <Box className="mt-sec container" sx={{ textAlign: "center" }}>
          <Box className="range-text">
            <h2>It's time to replace your GPS Rangefinder</h2>
            <p>
              Over confidence in playing ability leads golfers of all skills
              levels to attempt risky shots that inevitably cost them valuable
              strokes. It's not enough to simply know basic distances. Scoring
              requires making smart decisions about when to be aggressive and
              when to play safe.
            </p>
          </Box>
          <img
            src="assets/img/home/gps.svg"
            alt="gps-img"
            className="gps-img"
            style={{ width: "60%" }}
          />
        </Box>
      </div>

      {/* mobile screen */}
    </>
  );
}

export default RangeFinder;

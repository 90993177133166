import React from "react";
import { Typography, Grid, Card, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const PackageCard = ({
  packageTitle,
  subscriptionPrice,
  subscriptionCount,
  subscriptionText,
  simulatorSessions,
  price,
  priceDetail,
}) => {
  return (
    <Card className="package_card">
      <Grid container className="package_container">
        <Grid item xs={12} md={9} className="package_item">
          <Typography variant="h5" className="package_heading">
            <Box fontWeight="fontWeightBold">{packageTitle}</Box>
          </Typography>

          <Grid container className="subscription_container">
            <Grid item xs={12} md={3} className="subscription">
              <Typography variant="body2" className="subscription_text">
                {subscriptionText}
              </Typography>
              <Typography variant="body1" className="subscription_number">
                {subscriptionPrice}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} className="subscription_plus">
              <AddIcon className="material-icon" />
            </Grid>

            <Grid item xs={12} md={7} className="subscription">
              <Typography variant="body2" className="subscription_text">
                {simulatorSessions}
              </Typography>
              <Typography variant="body1" className="subscription_number">
                {subscriptionCount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} sx={{ padding: "1rem" }}>
          <Typography variant="h6" className="package_price">
            {price}
          </Typography>
          <Typography variant="subtitle1" className="package_price_detail">
            {priceDetail}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PackageCard;

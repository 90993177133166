import React from 'react'

const Banner = () => {
  return (
    <div className='container about_margin' >
        <div className="row">
            <div className="col-lg-7 align-center">
                <h1 className='ask-quest-head'>Frequently Asked Questions - FAQs</h1>
            </div>
            <div className="col-lg-5">
            <img src="/assets/img/products/person-conf.svg" alt="person-img" className='w-100'/>
            </div>
        </div>
      
    </div>
  );
}

export default Banner

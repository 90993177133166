import { Carousel } from "react-bootstrap";
import BlogCard from "./BlogCard";
import { useState } from "react";

const BlogCarousel = ({ blogs }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Carousel
      interval={isModalOpen ? null : 2000}
      pause="hover"
      controls={false}
    >
      {blogs?.slice(0, 3)?.map((blog) => (
        <Carousel.Item key={blog?.id}>
          <BlogCard
            isMain={true}
            cardDetails={blog}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default BlogCarousel;

import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const PrivacyData = () => {
  const data = [
    {
      heading: "1. Information We Collect",
      subheadings: [
        {
          subheading: "1.1. Personal Information",
          detail: `&bull; When you sign up for Noonan, we collect your name, date of birth, phone number, and payment information (including credit card details) to enable secure transactions for premium features and subscriptions.<br/>
          &bull; We may also collect location data (GPS-based) to provide you with localized golf course recommendations and to enhance your overall app experience.`,
        },
        {
          subheading: "1.2 Usage & Device Information",
          detail: `&bull; We may collect information about how you use the Noonan app, including but not limited to, the features you access, the clubs you select, and your interaction with the AI caddie's recommendations.<br/>
          &bull; Device information such as device type, operating system version, and unique device identifiers may be collected to optimize the app's performance and troubleshoot technical issues.`,
        },
      ],
    },
    {
      heading: "2. How we use your information",
      subheadings: [
        {
          subheading: "2.1. Personalization & Recommendations",
          detail: `We utilize the data collected to personalize your app experience, tailoring club recommendations and aiming assistance based on your playing style, preferences, and skill level.`,
        },
        {
          subheading: "2.2. Transaction Processing",
          detail: `
            Your payment information is used solely to process transactions for premium features or subscriptions within the app.
            `,
        },
        {
          subheading: "2.3. Communication",
          detail: `We may use your phone number to send you important notifications related to the app, including updates, service changes, and support messages.`,
        },
        {
          subheading: "2.4. App Improvement",
          detail: `Analyzing usage patterns and feedback helps us continuously improve Noonan, providing you with a better and more reliable experience.`,
        },
        {
          subheading: "2.5. Legal Compliance",
          detail: `In some cases, we may be required to use your information to comply with legal obligations or respond to lawful requests from authorities.`,
        },
      ],
    },

    {
      heading: "3. Data Sharing and Disclosure",
      subheadings: [
        {
          subheading: "3.1. Third Party Services",
          detail: `We may enage third-party service providers to assist us in delivering certain aspects of the app, such as payment processing or data analytics. These services providers will have limited access to your data and are contractually bound it to keep confidential and secure.`,
        },
        {
          subheading: "3.2. Legal Requirements",
          detail: `
            We may disclose your personal information if required to do so by law or in response to valid legal requests, such as court orders or subpoenas.
            `,
        },
        {
          subheading: "3.3. Business Transfers",
          detail: `
            If Noonan undergoes a merger, acquisition, or sale of assets, your personal information may be transferred as part of the business transition. We will notify you through the app or by email if your data becomes subject to a different privacy policy.
            `,
        },
        {
          subheading: "3.4. Data Security",
          detail: `
            We take the security of your information seriously and implement reasonable physical, technical, and administrative measures to safeguard it against unauthorized access, disclosure, or alteration. Despite our efforts, no method of transmission or storage is 100% secure, and we cannot guarantee the absolute security of your data.
            `,
        },
      ],
    },
    {
      heading: "4. Data Security",
      subheadings: [
        {
          detail: `We take the security of your information seriously and implement reasonable physical, technical, and administrative measures to safeguard it against unauthorized access, disclosure, or alteration. Despite our efforts, no method of transmission or storage is 100% secure, and we cannot guarantee the absolute security of your data.`,
        },
      ],
    },
    {
      heading: "5. Your Choices",
      subheadings: [
        {
          subheading: "5.1. Access and Update",
          detail: `You can review and update your personal information within the Noonan app's settings.`,
        },
        {
          subheading: "5.2. Location Data",
          detail: `You can enable or disable the app's access to your location through your device's settings. However, disabling this feature may limit certain functionalities.`,
        },
        {
          subheading: "5.3. Business Transfers",
          detail: `If Noonan undergoes a merger, acquisition, or sale of assets, your personal information may be transferred as part of the business transition. We will notify you through the app or by email if your data becomes subject to a different privacy policy.`,
        },
      ],
    },
    {
      heading: "6. Children’s Privacy",
      subheadings: [
        {
          detail: `Noonan is not intended for individuals under the age of 13. We do not knowingly collect personal information from children, and if we become aware of such data, we will take appropriate steps to delete it promptly.`,
        },
      ],
    },
    {
      heading: "7. Changes to Privacy Policy",
      subheadings: [
        {
          detail: `We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. When we make updates, we will revise the "Effective Date" at the beginning of the policy. We encourage you to review this policy periodically to stay informed about how we handle your information.`,
        },
      ],
    },
    {
      heading: "8. Contact Us",
      subheadings: [
        {
          detail: `If you have any questions, concerns, or requests regarding this Privacy Policy or the use of your personal information in Noonan, please contact us at:
          `,
          link: "admin@noonan.ai",
        },
      ],
    },
  ];
  return (
    <Box
      width={{ xs: "90%", md: "80%" }}
      display="flex"
      flexDirection="column"
      rowGap={{ xs: "1.8rem", md: "5rem" }}
      my="4rem"
    >
      {data.map((mainHeading, index) => (
        <Box key={index}>
          <Typography className="map_heading">{mainHeading.heading}</Typography>

          {mainHeading.subheadings.map((subheading, subIndex) => (
            <Box key={subIndex}>
              <Typography className="map_sub_heading">
                {subheading?.subheading}
              </Typography>
              <Typography className="map_details">
                <span dangerouslySetInnerHTML={{ __html: subheading.detail }} />
              </Typography>
              <Link to="mailto:admin@noonan.ai" className="map_link">
                {subheading?.link}
              </Link>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default PrivacyData;

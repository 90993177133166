import React from "react";
import { Link } from "react-router-dom";
const AboutBanner = () => {
	return (
		<div className="container about_margin">
			<div className="row">
				<div className="col-lg-6">
					<div className="about-banner-text">
						<h1>Our Mission</h1>
						<h3>Empowering golfers to reach their full potential</h3>
						<p>
							Our mission is to simplify the game of golf leveraging data &
							technology to help dedicated golfers get the most enjoyment out of
							each round.
						</p>
						<Link to="/products?scrollTo=contact_form">
							<button class="apply-btn">Apply Now </button>
						</Link>
					</div>
				</div>
				<div className="col-lg-6 align-center">
					<img
						src="/assets/img/about/about-banner-img.svg"
						alt="banner-img"
						className="w-100 about-img"
					/>
				</div>
			</div>
		</div>
	);
};

export default AboutBanner;

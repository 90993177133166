
import { Grid, Typography, Avatar, Box } from '@mui/material';

const PartnerBanner = () => {
  return (
    <Box sx={{ p:{ xs: '1rem', md: '2rem 6rem' } ,mt:'8rem'}}>
      <Grid container
       sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        gap:{xs:'3rem' ,md:'0rem'},
        Padding:{xs:'2rem',md:'0rem'},
        mb:{xs:'3rem',md:'0rem'}
      }}
      >
        <Grid
          item
          xs={12}
          md={7}
         sx={{
          pr:{xs:'0rem',md:'4rem'},
          textAlign:{xs:'center' ,md:'left'},
          
         }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '1.5', lg: '2.3' },
              fontWeight: 'bold',
              color: '#00ABAB',
              mb:{xs:'2rem',md:'1rem'}
            }}
          >
            Grow Your Golf Simulator Business
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: { xs: '1rem', lg: '1.3rem',},
              color: '#565656',
              lineHeight:'1.5'
            }}
          >
            Are you looking to increase revenue and expand the value your
            business brings to your customers?
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems:'center',
            
          }}
        >
          <Avatar
            variant="square"
            src="./assets/img/partners/partner_banner.svg"
            alt="partner_banner"
            sx={{ width: {xs:'70%',md:'95%'}, height: {xs:'70%',md:'95%'} ,mb:'3rem'}}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerBanner;

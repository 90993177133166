import { Box } from "@mui/material";
import "./privacypolicy.css";
import PrivacyHeader from "./PrivacyHeader";
import PrivacyData from "./PrivacyData";
import Trust from "./Trust";

const PrivacyPolicy = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <PrivacyHeader />
      <PrivacyData />
      <Trust />
    </Box>
  );
};

export default PrivacyPolicy;

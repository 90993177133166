import React from "react";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
const NoonanTracking = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className="mob-none" style={{ background: "rgb(230 247 247)" }}>
        <div className="container">
          <h2 className="product-head text-center pt-4">
            Noonan Caddie vs Shot Tracking GPS Apps
          </h2>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            className="noonan-w-slider pt-1"
          >
            <Carousel.Item>
              <div className="row text-center" style={{display:"flex", justifyContent:'center'}}>
                <div className="noonan-work-text">
                  <h3>Speed to Collect Shot Data</h3>
                  <p className="mt-3">
                    Any Caddie Feature requires shot data - and Noonan gets more
                    data about <br />
                    how you hit each club in a 1 hour simulator session than you
                    typically get <br />
                    tracking shots for a whole year.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    borderRadius:'2rem',
                    border: "1px solid black",
                    width: "95%",
                  }}
                >
                  <div className="col-5">
                    <div className="tracking-text">
                      <h4>1 Hour</h4>
                      <h5>Hitting shots on a golf simulator</h5>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="tracking-text">
                      <h4>=</h4>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="tracking-text">
                      <h4>2 Weeks</h4>
                      <h5>Tracking shots while playing outside</h5>
                    </div>
                  </div>
                </div>
                <p className="paragraph-caddie">
                  Arccos Caddie recommends 5 rounds minimum before you can use
                  their Caddie feature. But ask yourself, how often do you hit
                  every club during a given round?
                </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row text-center">
                <div className="noonan-work-text">
                  <h3>
                    Built-in Intelligence to help golfers avoid Penalty Areas
                  </h3>
                </div>
                <div className="space-btw">
                  <div className="tracking-heading">
                    <h4>Noonan Caddie</h4>
                  </div>
                  <div className="tracking-heading">
                    <h4>Shot Tracking GPS Apps</h4>
                  </div>
                </div>
                <div className="tracking-img text-center">
                  <img
                    src="/assets/img/products/Tracking1.svg"
                    alt="track-img"
                  />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row text-center">
                <div className="noonan-work-text">
                  <h3>COMING SOON - Lie Detector</h3>
                  <p className="mt-3">
                    The condition of the lie has a significant impact on how far
                    the ball flies and <br />
                    how much it spins
                  </p>
                </div>
                <div className="space-around">
                  <div className="tracking-heading">
                    <h4>Noonan Caddie</h4>
                  </div>
                  <div className="tracking-heading">
                    <h4>Shot Tracking GPS Apps</h4>
                  </div>
                </div>
                <div className="col-5 align-start">
                  <img
                    src="/assets/img/products/mob-img-lie.svg"
                    alt="mob-img-lie"
                  />
                  <img
                    src="/assets/img/products/true.svg"
                    alt="mob-img-lie"
                    className="close-true-img"
                  />
                </div>
                <div className="col-2">
                  <img src="/assets/img/products/Line17.svg" alt="Line-img" />
                </div>
                <div className="col-5 align-start">
                  <img
                    src="/assets/img/products/close-red.svg"
                    alt="mob-img-lie"
                    className="close-true-img"
                  />
                  <img
                    src="/assets/img/products/person-conf.svg"
                    alt="person-img"
                  />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>

      {/* mobile-screen */}
      <div className="desktop-none">
        <div style={{ background: "#E6F7F7", padding: "2rem 0 3rem 0" }}>
          <h2 className="product-head text-center pt-4 mbp">
            Noonan Caddie vs other Shot Tracking GPS Apps
          </h2>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            className="noonan-w-slider pt-1 mob-track"
          >
            <Carousel.Item>
              <div className="row text-center">
                <div className="noonan-work-text">
                  <h3 className="mbp">Speed to Collect Shot Data</h3>
                  <p className="mt-3 mbp mb-0">
                    Any Caddie Feature requires shot data - and Noonan gets more
                    data about how you hit each club in a 1 hour simulator
                    session than you typically get tracking shots for a whole
                    year.
                  </p>
                </div>
                <div className="box-houre d-flex">
                  <div className="tracking-text">
                    <h4>1 Hour</h4>
                    <p>Hitting shots on a golf simulator</p>
                  </div>
                  <div className="tracking-text">
                    <h4>=</h4>
                  </div>
                  <div className="tracking-text">
                    <h4>1 Year</h4>
                    <p>Tracking shots while playing outside</p>
                  </div>
                </div>
                <p className="paragraph-caddie px-4">
                  Arccos Caddie recommends 5 rounds minimum before you can use
                  their Caddie feature. But ask yourself, how often do you hit
                  every club during a given round?
                </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ textAlign: "center", padding: "0 2rem" }}>
                <div className="noonan-work-text">
                  <h3>
                    Built-in Intelligence to help golfers avoid Penalty Areas
                  </h3>
                </div>
                <div className="space-btw">
                  <div className="tracking-heading">
                    <h4>Noonan Caddie</h4>
                  </div>
                  <div className="tracking-heading">
                    <h4>Other Apps</h4>
                  </div>
                </div>
                <div className="tracking-img text-center">
                  <CheckIcon style={{ color: "#00abab" }} />
                  <p>
                    Calculate if a club <br /> will stay short?
                  </p>
                  <CheckIcon style={{ color: "#00abab" }} />
                </div>
                <div className="tracking-img text-center">
                  <CheckIcon style={{ color: "#00abab" }} />
                  <p>
                    Calculate if a club will <br /> Carry?
                  </p>
                  <CloseIcon style={{ color: "#f44336" }} />
                </div>
                <div className="tracking-img text-center">
                  <CheckIcon style={{ color: "#00abab" }} />
                  <p>
                    Provides are <br /> commended aim line
                  </p>
                  <CloseIcon style={{ color: "#f44336" }} />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row text-center">
                <div className="noonan-work-text">
                  <h3>COMING SOON - Lie Detector</h3>
                  <p className="mt-3">
                    The condition of the lie has a significant impact on how far
                    the ball flies and <br />
                    how much it spins
                  </p>
                </div>
                <div className="mt-3">
                  <img
                    src="/assets/img/products/mob-img-lie1.svg"
                    alt="mob-img-lie"
                  />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default NoonanTracking;

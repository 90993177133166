import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const Advantage = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="advantage_container">
      <div
        className="container mt-sec adv-bg-mob"

      >
        <h2 className="adv-head">The Noonan Advantage</h2>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          className="intro-slider"
        >
          <Carousel.Item>
            <h3 className="intro-slider-head mb-font">
              Club Recommendations You Can Trust
            </h3>
            <div className="row mob-adv-center">
              <div className="col-lg-5 wind-img">
                <div className="text-left-img">
                  <h4>
                    Calculates which club has the highest odds for success based
                    on the dispersion pattern of your{" "}
                    <span> Carry Distance, Total Distance & Aim Line</span>
                  </h4>
                </div>
                <img
                  src="/assets/img/home/advantage1.svg"
                  alt="slider-img"
                  className="advantage1-img"
                />
              </div>
              <div className="col-lg-2 vertical-line"></div>
              <div className="col-lg-5 wind-img">
                <div className="text-left-img">
                  <h4>
                    Automatically calculates distance adjustments for:{" "}
                    <span> Wind, Temperature & Elevation Change </span>
                  </h4>
                </div>
                <img
                  src="/assets/img/home/advantage2.svg"
                  alt="slider-img"
                  className="advantage2-img"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <h3 className="intro-slider-head mb-font">
              Intelligently Helps You Avoid Penalty Areas
            </h3>
            <div className="row  mob-adv-center">
              <div className="col-lg-4 col-line">
                <div className="second-slider-text">
                  <div className="slider-paragraph">
                    <h4>
                      Recommends clubs that will Carry penalty areas between you
                      and the hole
                    </h4>
                  </div>
                  <img src="/assets/img/home/advantage3.svg" alt="slider-img" />
                </div>
              </div>
              <div className="col-lg-4 col-line">
                <div className="second-slider-text">
                  <div className="slider-paragraph">
                    <h4>
                      Recommends clubs that will Stay Short of hazards
                    </h4>
                  </div>
                  <img src="/assets/img/home/advantage4.svg" alt="slider-img" />
                </div>
              </div>
              <div className="col-lg-4 col-line" style={{ border: "none" }}>
                <div className="second-slider-text">
                  <div className="slider-paragraph">
                    <h4>
                      Displays a safe Aim Line that keeps most shots out of
                      the penalty area
                    </h4>
                  </div>
                  <div className="second-last-img text-center">
                    <img
                      src="/assets/img/home/advantage5.svg"
                      alt="slider-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <h3 className="intro-slider-head">
              COMING SOON - Lie Detector AI Predicts How Your Ball Will React After Impact
            </h3>
            <div className="row mob-adv-center">
              <div className="col-lg-4 col-line">
                <div className="second-slider-text">
                  <div className="slider-paragraph">
                    <h4 style={{ fontWeight: "600" }}>Flyer Lie</h4>
                    <p>
                      More speed & Less Spin - Ball will fly further than normal
                    </p>
                  </div>
                  <img src="/assets/img/home/advantage6.svg" alt="slider-img" />
                </div>
              </div>
              <div className="col-lg-4 col-line">
                <div className="second-slider-text">
                  <div className="slider-paragraph">
                    <h4 style={{ fontWeight: "600" }}>Deep Rough</h4>
                    <p>
                      Grass will grip the club causing ball to come out lower
                      with less spin Light Rough Less Difficult - Ball will fly
                      with
                    </p>
                  </div>
                  <img src="/assets/img/home/advantage7.svg" alt="slider-img" />
                </div>
              </div>
              <div className="col-lg-4 col-line" style={{ border: "none" }}>
                <div className="second-slider-text">
                  <div className="slider-paragraph">
                    <h4 style={{ fontWeight: "600" }}>Light Rough</h4>
                    <p>
                      Less Difficult - Ball will fly with normal trajectory but
                      roll further
                    </p>
                  </div>
                  <img src="/assets/img/home/advantage8.svg" alt="slider-img" />
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      {/* <div className="container mt-sec">
      <h2 class="adv-head">The Noonan Advantage</h2>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        className="intro-slider"
      >
        <Carousel.Item>
          <h3 className="intro-slider-head">
            Club Recommendations You Can Trust
          </h3>
          <div className="row">
            <div className="col-lg-5">
              <div className="text-left-img">
                <h4>
                  Calculates which club has the highest odds for success based
                  on the dispersion pattern of your
                </h4>
                <ul style={{ fontWeight: "600" }}>
                  <li>Carry Distance</li>
                  <li>Total Distance</li>
                  <li>Aim Line</li>
                </ul>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <p className="solid-text">
                    7-iron <br />
                    75%-Hit Green <br /> 5%-Hit Water
                  </p>
                </div>
                <div className="col-lg-8">
                  <img
                    src="/assets/img/home/advantage1.svg"
                    alt="slider-img"
                    className="advantage1-img"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 vertical-line"></div>
            <div className="col-lg-5 text-end">
              <h4 className="right-side-text-adv">
                Includes Distance Adjustments for Wind,Temperature & Elevation
                Change
              </h4>
              <img src="/assets/img/home/advantage2.svg" alt="slider-img" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <h3 className="intro-slider-head">
            Intelligently Helps You Avoid Penalty Areas
          </h3>
          <div className="row">
            <div className="col-lg-4 col-line">
              <div className="second-slider-text">
                <div className="slider-paragraph">
                  <h4>
                    Recommends clubs that will Carry penalty areas between you and the hole
                  </h4>
                </div>
                <img src="/assets/img/home/advantage3.svg" alt="slider-img" />
              </div>
            </div>
            <div className="col-lg-4 col-line">
              <div className="second-slider-text" >
                <div className="slider-paragraph">
                  <h4>
                    Recommends clubs that will Stay Short penalty areas beyond the hole printer setting the fairway.
                  </h4>
                </div>
                <img src="/assets/img/home/advantage4.svg" alt="slider-img" />
              </div>
            </div>
            <div className="col-lg-4 col-line" style={{ border: 'none' }}>
              <div className="second-slider-text">
                <div className="slider-paragraph">
                  <h4>
                    Displays a safe Aim Line that keeps most shots out of the penalty area
                  </h4>
                </div>
                <div className="second-last-img text-center">
                  <img src="/assets/img/home/advantage5.svg" alt="slider-img" />
                </div>
              </div>
            </div>

          </div>
        </Carousel.Item>
        <Carousel.Item>
          <h3 className="intro-slider-head">
            Lie Detector AI Predicts How Your Ball Will React After Impact
          </h3>
          <div className="row">
            <div className="col-lg-4 col-line">
              <div className="second-slider-text">
                <div className="slider-paragraph">
                  <h4 style={{ fontWeight: '600' }}>Flyer Lie</h4>
                  <p>More speed & Less Spin - Ball will fly further than normal</p>
                </div>
                <img src="/assets/img/home/advantage6.svg" alt="slider-img" />
              </div>
            </div>
            <div className="col-lg-4 col-line">
              <div className="second-slider-text" >
                <div className="slider-paragraph">
                  <h4 style={{ fontWeight: '600' }}>Deep Rough</h4>
                  <p>
                    Grass will grip the club causing ball to come out lower with less spin
                    Light Rough Less Difficult - Ball will fly with
                  </p>
                </div>
                <img src="/assets/img/home/advantage7.svg" alt="slider-img" />
              </div>
            </div>
            <div className="col-lg-4 col-line" style={{ border: 'none' }}>
              <div className="second-slider-text">
                <div className="slider-paragraph">
                  <h4 style={{ fontWeight: '600' }}>Light Rough</h4>
                  <p>Less Difficult - Ball will fly with normal trajectory but roll further</p>
                </div>
                <img src="/assets/img/home/advantage7.svg" alt="slider-img" />
              </div>
            </div>

          </div>
        </Carousel.Item>
      </Carousel>
    </div> */}
    </div>
  );
};

export default Advantage;

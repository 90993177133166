import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import moment from "moment";
import { fetchBlogs } from "../apis/fetchBlogData.js";
import { PortableText } from "@portabletext/react";
// import updateBlogViews from "../graphql/mutateBlogs.js";

import { SanityRecorIdContext } from "./context/SanityRecorIdContext.js";
import { incrementViews } from "../apis/updateBlogViews.js";
import { removeDuplicatesById } from "./LatestBlogs.js";

export const convertToSlug = (text) => {
  if (!text || typeof text !== "string") {
    return "";
  }
  return text
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .trim()
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
};

const BlogDetail = () => {
  const { blogRoute } = useParams();
  const { id } = useContext(SanityRecorIdContext);
  const [blog, setBlog] = useState(null);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [blogIndex, setBlogIndex] = useState(-1);
  const navigate = useNavigate();
  const hasUpdatedViews = useRef(false);

  const extractHeadings = (blocks) => {
    const headings = [];
    blocks.forEach((block) => {
      if (
        block._type === "block" &&
        (block.style === "h1" || block.style === "h2")
      ) {
        const headingText = block.children.map((child) => child.text).join("");
        headings.push({ style: block.style, text: headingText });
      }
    });
    return headings;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogsData = await fetchBlogs();
        setBlogs(blogsData);

        const selectedBlogIndex = blogsData.findIndex(
          (b) => convertToSlug(b.blog_title) === blogRoute
        );
        if (selectedBlogIndex !== -1) {
          const selectedBlog = blogsData[selectedBlogIndex];
          setBlog(selectedBlog);
          setBlogIndex(selectedBlogIndex);
          setHeadings(extractHeadings(selectedBlog?.content || []));
        } else {
          console.log(`Blog with route "${blogRoute}" not found`);
        }

        const latest = blogsData
          .filter((b) => convertToSlug(b?.blog_title) !== blogRoute)
          .sort((a, b) => moment(b?._createdAt).diff(moment(a?._createdAt)))
          .slice(0, 3);
        setLatestBlogs(latest);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchData();
  }, [blogRoute]);

  useEffect(() => {
    incrementViews(id);
  }, []);

  const components = {
    marks: {
      link: ({ children, value }) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === "_blank" && "noindex nofollow"}
            className="link-hover-effect"
          >
            {children}
          </a>
        );
      },
    },
    block: {
      normal: ({ children }) => (
        <Typography
          sx={{
            mt: 1,
            color: "rgba(0, 0, 0, 0.90)",
            fontSize: "1.125rem",
            textAlign: "justify",
          }}
        >
          {children}
        </Typography>
      ),
      h1: ({ children }) => {
        const filteredChildren = children.filter(
          (child) =>
            !(child && typeof child === "object" && "$$typeof" in child)
        );
        const id = convertToSlug(filteredChildren.join(""));
        return (
          <Typography
            component="h1"
            id={id}
            sx={{
              color: "#00abab",
              fontWeight: 600,
              mt: 4,
              fontSize: "1.5rem",
            }}
          >
            {filteredChildren}
          </Typography>
        );
      },
      h2: ({ children }) => {
        const filteredChildren = children.filter(
          (child) =>
            !(child && typeof child === "object" && "$$typeof" in child)
        );
        const id = convertToSlug(filteredChildren.join(""));
        return (
          <Typography
            component="h2"
            id={id}
            sx={{
              fontWeight: 600,
              fontSize: "1.2rem",
              mt: 2,
            }}
          >
            {filteredChildren}
          </Typography>
        );
      },
      h3: ({ children }) => {
        const filteredChildren = children.filter(
          (child) =>
            !(child && typeof child === "object" && "$$typeof" in child)
        );
        const id = convertToSlug(filteredChildren.join(""));
        return (
          <Typography
            component="h3"
            id={id}
            sx={{
              fontWeight: 600,
              fontSize: "1.1rem",
              mt: 2,
            }}
          >
            {filteredChildren}
          </Typography>
        );
      },
      h4: ({ children }) => {
        const filteredChildren = children.filter(
          (child) =>
            !(child && typeof child === "object" && "$$typeof" in child)
        );
        const id = convertToSlug(filteredChildren.join(""));
        return (
          <Typography
            component="h4"
            id={id}
            sx={{
              fontWeight: 600,
              fontSize: "1rem",
              mt: 2,
            }}
          >
            {filteredChildren}
          </Typography>
        );
      },
      h5: ({ children }) => {
        const filteredChildren = children.filter(
          (child) =>
            !(child && typeof child === "object" && "$$typeof" in child)
        );
        const id = convertToSlug(filteredChildren.join(""));
        return (
          <Typography
            component="h5"
            id={id}
            sx={{
              fontWeight: 600,
              fontSize: "0.95rem",
              mt: 2,
            }}
          >
            {filteredChildren}
          </Typography>
        );
      },
      h6: ({ children }) => {
        const filteredChildren = children.filter(
          (child) =>
            !(child && typeof child === "object" && "$$typeof" in child)
        );
        const id = convertToSlug(filteredChildren.join(""));
        return (
          <Typography
            component="h6"
            id={id}
            sx={{
              fontWeight: 600,
              fontSize: "0.9rem",
              mt: 2,
            }}
          >
            {filteredChildren}
          </Typography>
        );
      },
      blockquote: ({ children }) => (
        <Typography
          component="blockquote"
          sx={{
            fontStyle: "italic",
            color: "rgba(0, 0, 0, 0.75)",
            borderLeft: "4px solid #00abab",
            pl: 2,
            ml: 2,
            mt: 2,
              wordWrap:'break-word'
          }}
        >
          {children}
        </Typography>
      ),
    },
  };

  const truncateText = (text, length) => {
    return text?.length > length ? text?.slice(0, length) + "..." : text;
  };

  const handlePreviousClick = () => {
    if (blogIndex > 0) {
      const previousBlogRoute = convertToSlug(blogs[blogIndex - 1].blog_title);
      incrementViews(blogs[blogIndex - 1]._id);
      navigate(`/blogs/${previousBlogRoute}`);
    }
  };

  const handleNextClick = () => {
    if (blogIndex < blogs?.length - 1) {
      const nextBlogRoute = convertToSlug(blogs[blogIndex + 1].blog_title);
      incrementViews(blogs[blogIndex + 1]._id);
      navigate(`/blogs/${nextBlogRoute}`);
    }
  };

  if (!blog) {
    return <Typography>Blog not found</Typography>;
  }

  const handleTableOfContentClick = (headingId) => {
    const headingElement = document.getElementById(headingId);
    if (headingElement) {
      headingElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  return (
    <div>
      <Helmet>
        <meta property="og:title" content={blog.blog_title} />
        <meta property="og:image" content={blog.banner_image} />
        <meta property="og:type" content="article" />
      </Helmet>
      <Box bgcolor="#00ABAB" mt="4rem" py={{ xs: "3em", md: "6rem" }}>
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            mb={{ xs: "1.25rem", md: "3rem" }}
          >
            <Link to="/blogs" className="display_btn">
              <Button
                startIcon={<ArrowBackIcon />}
                className="back_btn"
                disableRipple
              >
                Back
              </Button>
            </Link>
            <Typography className="posted_date">
              {`POSTED ON ${moment(blog._createdAt).format("MMMM DD, YYYY")}`}
            </Typography>
          </Box>
          <Typography className="blog_heading">{blog.blog_title}</Typography>
        </Container>
      </Box>
      <Container>
        <Grid container my="3rem" spacing={{ xs: 1, lg: 3 }} pt={3}>
          <Grid item xs={12} md={8} order={{ xs: 1, md: 0 }}>
            <img
              src={blog.banner_image}
              alt="blog_header_img"
              className="blog_img"
            />
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 0.90)",
                fontSize: "1.125rem",
                textAlign: "justify",
              }}
            >
              {blog?.opening_paragraph}
            </Typography>
            <PortableText value={blog?.content} components={components} />
            <Box
              display={{ xs: "flex", md: "flex" }}
              flexDirection={{ xs: "column", md: "row" }}
              gap={1}
              justifyContent="space-between"
              my={2}
            >
              <Button
                startIcon={
                  <ArrowBackRoundedIcon style={{ fontSize: "2rem" }} />
                }
                className={
                  blogIndex <= 0 ? "disable_button" : "blog_details_button"
                }
                disableRipple
                onClick={handlePreviousClick}
                disabled={blogIndex <= 0}
              >
                Previous
              </Button>
              <Button
                endIcon={
                  <ArrowForwardRoundedIcon style={{ fontSize: "2rem" }} />
                }
                className={
                  blogIndex >= blogs?.length - 1
                    ? "disable_button"
                    : "blog_details_button"
                }
                disableRipple
                onClick={handleNextClick}
                disabled={blogIndex >= blogs?.length - 1}
              >
                Next
              </Button>
            </Box>
          </Grid>
          
          <Grid item xs={12} md={4} order={{ xs: 0, md: 1 }}>
            {headings.length > 0 && (
              <Card className="table_of_content_card">
                <Typography className="table_of_content_heading" mb="1rem">
                  Table of Contents
                </Typography>
                {headings.map((heading, index) => (
                  <Typography
                    key={index}
                    component="a"
                    onClick={() =>
                      handleTableOfContentClick(convertToSlug(heading?.text))
                    }
                    sx={{
                      display: "block",
                      marginLeft: heading.style === "h2" ? "1rem" : "0rem",
                      textDecoration: "none",
                      fontWeight:heading.style === "h2" ? 500:'bold',
                      color: "inherit",
                      "&:hover": {
                        textDecoration: "underline",
                        color: "#00abab",
                      },
                      cursor: "pointer",
                    }}
                  >
                    {heading?.text}
                  </Typography>
                ))}
              </Card>
            )}
            <Box display={{ xs: "none", md: "block" }} pt="1rem">
              <Typography className="table_of_content_heading" mb="1rem">
                Latest Blogs
              </Typography>
              {latestBlogs?.map((latestBlog, index) => (
                <Grid
                  container
                  spacing={1}
                  key={latestBlog?.blog_title}
                  component={Link}
                  to={`/blogs/${convertToSlug(latestBlog?.blog_title)}`}
                  sx={{ textDecoration: "none" }}
                >
                  <Grid item xs={4}>
                    <img
                      src={latestBlog?.banner_image}
                      className="recant_blog_img"
                      alt={latestBlog?.blog_title}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    className="recant_blog_container"
                  >
                    <Typography className="recant_blog_heading">
                      {truncateText(latestBlog?.blog_title, 40)}
                    </Typography>
                    <Typography className="recant_blog_date">
                      {moment(latestBlog?._createdAt).format("MMM DD, YYYY")}
                    </Typography>
                  </Grid>
                  {index < latestBlogs.length - 1 && (
                    <Grid item xs={12}>
                      <Divider
                        sx={{
                          my: ".2rem",
                          backgroundColor: "#00ABAB",
                          width: "100%",
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Box>
          </Grid>

          <Grid item display={{ xs: "block", md: "none" }} order={2}>
            <Box p="1rem">
              <Typography className="table_of_content_heading" mb="1rem">
                Latest Blogs
              </Typography>
              {latestBlogs?.map((latestBlog, index) => (
                <Grid
                  container
                  spacing={1}
                  key={latestBlog?.blog_title}
                  component={Link}
                  to={`/blogs/${convertToSlug(latestBlog?.blog_title)}`}
                  sx={{ textDecoration: "none" }}
                >
                  <Grid item xs={4}>
                    <img
                      src={latestBlog?.banner_image}
                      className="recant_blog_img"
                      alt={latestBlog?.blog_title}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    className="recant_blog_container"
                  >
                    <Typography className="recant_blog_heading">
                      {truncateText(latestBlog?.blog_title, 40)}
                    </Typography>
                    <Typography className="recant_blog_date">
                      {moment(latestBlog?._createdAt).format("MMM DD, YYYY")}
                    </Typography>
                  </Grid>

                  {index < latestBlogs.length - 1 && (
                    <Grid item xs={12}>
                      <Divider
                        sx={{
                          my: ".2rem",
                          backgroundColor: "#00ABAB",
                          width: "100%",
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default BlogDetail;

import React, { useState, useContext } from "react";
import { Box, Grid, Typography, Button, Chip, Modal } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import moment from "moment";
import { convertToSlug } from "./BlogDetail";
import { FaXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import RedditIcon from "@mui/icons-material/Reddit";
import { SanityRecorIdContext } from "../blogs/context/SanityRecorIdContext";

const BlogCard = ({ cardDetails, isMain, isModalOpen, setIsModalOpen }) => {
  const [isCopied, setIsCopied] = useState(false);

  const { setId } = useContext(SanityRecorIdContext);
  const navigate = useNavigate();

  const handleShareClick = () => {
    setIsModalOpen(true);
  };

  const truncateText = (text, length) => {
    if (!text || typeof text !== "string") {
      return "";
    }
    return text.length > length ? text.slice(0, length) + "..." : text;
  };

  const handleCardClick = () => {
    setId(cardDetails._id);
    navigate(`/blogs/${convertToSlug(cardDetails.blog_title)}`);
  };

  const handleReadMoreClick = (e) => {
    e.stopPropagation();
    setId(cardDetails._id);
    navigate(`/blogs/${convertToSlug(cardDetails.blog_title)}`);
  };

  const formatViews = (views) => {
    if (views >= 1000) {
      return `${(views / 1000).toFixed(1)}k`;
    }
    return views.toString();
  };

  return (
    <>
      <Grid
        container
        onClick={!isMain ? handleCardClick : undefined}
        component={!isMain && "div"}
        sx={{
          borderRadius: "1rem",
          my: 4,
          cursor: !isMain && "pointer",
          bgcolor: isMain ? "rgba(0, 171, 171, 0.06)" : "transparent",
          textDecoration: "none",
          "&:hover": {
            bgcolor: !isMain && "rgba(0, 171, 171, 0.06)",
          },
        }}
      >
        <Grid
          item
          md={5.5}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: !isMain && "1rem",
              overflow: "hidden",
            }}
          >
            <img
              src={cardDetails.banner_image}
              alt="Card-Media"
              className="blog_main_card_img"
            />
          </Box>
        </Grid>
        <Grid
          item
          md={isMain ? 6 : 6.5}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: isMain ? "space-around" : "space-between",
            padding: isMain
              ? {
                  xs: ".5rem",
                  md: ".3rem 1rem",
                  lg: "1.2rem 1.2rem 1.2rem 2em",
                }
              : { xs: ".5rem", md: "0rem .5rem", lg: "1rem" },
          }}
        >
          <Box>
            <Typography className={isMain && "blog_main_card_category"}>
              {isMain && "Recent"}
            </Typography>
            <Typography
              mb=".2rem"
              mt={!isMain ? ".2rem" : "1rem"}
              className={
                !isMain ? "blog_sub_card_heading" : "blog_main_card_heading"
              }
            >
              {truncateText(cardDetails?.blog_title, 50)}
            </Typography>
            <Typography className="blog_card_description" mb=".2rem">
              {truncateText(cardDetails?.opening_paragraph, 150)}
            </Typography>
          </Box>
          <Box>
            <Box
              display="flex"
              justifyContent={!isMain && "space-between"}
              alignItems="center"
              gap={1}
            >
              <Typography className="blog_card_date">
                {moment(cardDetails._createdAt).format("MMM DD, YYYY")}
              </Typography>
              {isMain && (
                <Box
                  sx={{
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    bgcolor: "rgba(0, 0, 0, 0.7)",
                  }}
                />
              )}
              <Typography className="blog_card_views">
                {`${formatViews(cardDetails.blog_views)} ${
                  cardDetails.blog_views !== 1 ? "views" : "View"
                }`}
              </Typography>
            </Box>
            <Box>
              {isMain && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: { xs: ".2rem", md: "1rem" },
                    mt: 3,
                  }}
                >
                  <Box
                    onClick={handleReadMoreClick}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="outlined"
                      className="blog_read_more_btn"
                      disableRipple
                    >
                      Read More
                    </Button>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Button
                      className="blog_share_btn"
                      startIcon={<ShareOutlinedIcon />}
                      disableRipple
                      onClick={handleShareClick}
                    >
                      Share
                    </Button>
                    {!isCopied ? (
                      <Button
                        disableRipple
                        className="blog_share_btn"
                        onClick={() => {
                          setId(cardDetails._id);
                          navigator.clipboard.writeText(
                            `${
                              process.env.REACT_APP_BASE_URL
                            }/blogs/${convertToSlug(cardDetails.blog_title)}`
                          );
                          setIsCopied(true);
                          setTimeout(() => setIsCopied(false), 2000);
                        }}
                        startIcon={
                          <img
                            src="/assets/img/blogs/copy_link.webp"
                            alt="link-icon-black"
                          />
                        }
                      ></Button>
                    ) : (
                      <Chip
                        icon={<DoneIcon style={{ color: "white" }} />}
                        label="Copied"
                        sx={{
                          backgroundColor: "#00abab",
                          color: "white",
                          fontSize: { xs: ".8rem", md: "1rem" },
                          padding: {
                            xs: "1.4rem .2rem",
                            md: "1.5rem .3rem !important",
                          },

                          borderRadius: "30px",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            <Modal
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  bgcolor: "#fff",
                  borderRadius: 3,
                  p: 1,
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <FacebookShareButton
                  url={`${process.env.REACT_APP_BASE_URL}/blogs/${convertToSlug(
                    cardDetails.blog_title
                  )}`}
                >
                  <FacebookRoundedIcon
                    sx={{ color: "#4267B2", fontSize: 40 }}
                  />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={`${process.env.REACT_APP_BASE_URL}/blogs/${convertToSlug(
                    cardDetails.blog_title
                  )}`}
                >
                  <LinkedInIcon sx={{ color: "#0077B5", fontSize: 40 }} />
                </LinkedinShareButton>
                <RedditShareButton
                  url={`${process.env.REACT_APP_BASE_URL}/blogs/${convertToSlug(
                    cardDetails.blog_title
                  )}`}
                >
                  <RedditIcon sx={{ color: "#FF5700", fontSize: 40 }} />
                </RedditShareButton>
                <TwitterShareButton
                  url={`${process.env.REACT_APP_BASE_URL}/blogs/${convertToSlug(
                    cardDetails.blog_title
                  )}`}
                >
                  <FaXTwitter style={{ fontSize: "1.8rem", color: "black" }} />
                </TwitterShareButton>
              </Box>
            </Modal>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BlogCard;

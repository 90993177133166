import client from "../../sanityClient";
import { v4 as uuidv4 } from 'uuid';

export const incrementViews = async (blogId) => {
  try {
    const today = new Date().toISOString().split('T')[0];

    const blog = await client.getDocument(blogId);

    const newViews = (blog.blog_views || 0) + 1;

    let viewsHistory = blog.viewsHistory || [];

    const todayEntryIndex = viewsHistory.findIndex(entry => entry.date === today);

    if (todayEntryIndex !== -1) {
      viewsHistory[todayEntryIndex].views += 1;
    } else {
      viewsHistory.push({ _key: uuidv4(), date: today, views: 1 });
    }

    await client
      .patch(blogId)
      .set({ blog_views: newViews, viewsHistory })
      .commit();

  } catch (error) {
    console.error('Error incrementing blog views:', error.message);
  }
};


import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const Banner = () => {
	return (
		<>
			<Box className="banner-img">
				<Box
					className="container"
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<div className="row">
						<div className="col-lg-6">
							<Box>
								<h1>Club & Aim Selection Made Easy!</h1>
								<p className="paragraph1-banner">
									Your on-course caddie helping you{" "}
									<span> shoot lower scores </span>
								</p>
								<p>Join the Early Adopter Program</p>

								<Link to="/products?scrollTo=contact_form">
									<button className="apply-btn w-100">Apply Now</button>
								</Link>
							</Box>
						</div>

						<div className="col-lg-6 align-end justify-content-end">
							<Box className="right-img">
								<img
									src="./assets/img/home/Coming-soon1.svg"
									alt="Comming-soon"
									className="img-fluid"
								/>
							</Box>
						</div>
					</div>
				</Box>
			</Box>

			{/* mobile screen */}

			<Box className="desktop-none mob-banner-img ">
				<Box className="text-box-b-mob">
					<h1>
						Club & Aim Selection <br /> <span> made easy! </span>
					</h1>
					<p className="paragraph1-banner">
						Your on-course caddie helping you <br />{" "}
						<span> shoot lower scores </span>
					</p>
					<p>Join the Early Adopter Program</p>
					<Link to="/products?scrollTo=contact_form">
						<button className="apply-btn">Apply Now</button>
					</Link>
				</Box>
			</Box>
		</>
	);
};

export default Banner;

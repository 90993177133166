const DispersionPattern = () => {
  return (
    <div className="container about_margin">
      <h1 className="product-head">The Power of Dispersion Patterns</h1>
      <div className="row">
        <div className="col-lg-7 col-md-7 align-center">
          <div className="product-text">
            <h2>Golf is like Black Jack</h2>
            <p>
              Every shot in golf is like being dealt a new hand of Black Jack.
              You don't know what the next card is going to be - just like you
              don't know exactly where your next shot will land. To gain an
              advantage over the House (i.e. the Course) you have to play the
              percentages.
            </p>
          </div>
        </div>
        <div className="col-lg-5 col-md-5">
          <img
            src="/assets/img/products/Golf-img.svg"
            alt="Golf-img"
            className="w-100"
          />
        </div>
      </div>
      <div className="row golf-mt-row  mob-row-golf">
        <div className="col-lg-5 col-md-5">
          <img
            src="/assets/img/products/Golf-img2.webp"
            alt="Golf-img"
            className="w-100"
          />
        </div>
        <div className="col-lg-7 col-md-7 align-center">
          <div className="product-text">
            <h2>The Golf Equivalent to Counting Cards</h2>
            <p>
              The shots you hit with each club form a unique dispersion pattern
              -a random scattering similar to a shotgun shot sprayed over a
              target. No single shot more predictable than the next. We use
              these patterns to predict the AREA where 95% of your shots will
              land - just like gamblers count cards to predict which card is
              coming next.
            </p>
          </div>
        </div>
      </div>
      <div className="row golf-mt-row align-center">
        <div className="col-lg-7 col-md-7">
          <div className="product-text">
            <h2>Taking down the "House"</h2>
            <p>
              When you look at your club's dispersion pattern on the course, you
              can tell immediately which are 'High-Percentage' shots and which
              are 'Low-Percentage' shots. By simply making better decisions, you
              can hit more greens and significantly reduce your odds of making
              Bogeys, or worse.
            </p>
          </div>
        </div>
        <div className="col-lg-5 col-md-5">
          <div className="row">
            <div className="col-6">
              <img
                src="/assets/img/products/Golf-img3.svg"
                alt="Golf-img"
                className="w-100"
              />
            </div>
            <div className="col-6">
              <img
                src="/assets/img/products/Golf-img4.svg"
                alt="Golf-img"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DispersionPattern;

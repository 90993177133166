import React from "react";
import ContactForm from "../common/ContactForm";
import { Box, Grid } from "@mui/material";

const ApplyForm = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}  className="container">
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: "2rem", mb: { md: "4rem" },}}
      >
        <Grid
          item
          xs={12}
          md={5.8}
          className="form-left-text"
          sx={{ px: "1rem" }}
        >
          <h3 className="text-center-mob">Get in touch with us</h3>
          <p>
            If you have any product related questions or suggestions please feel
            free to contact us!
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          md={5.8}
          sx={{
            bgcolor: { xs: "#e6f7f7", md: "transparent" },
            p: { xs: "2.5rem 1rem", md: "1rem" },
          }}
        >
          <ContactForm />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ApplyForm;

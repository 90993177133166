import React from 'react'
import AboutBanner from './AboutBanner'
import OurTeam from './OurTeam'
import ApplyForm from './ApplyForm'

const About = () => {
  return (
    <div>
      <AboutBanner />
      <OurTeam />
      <ApplyForm />
    </div>
  )
}

export default About
import { Box, Typography } from "@mui/material";
import React from "react";

const Trust = () => {
  return (
    <Box className='trust_box'>
      <Typography className="trust_text">
        Thank you for trusting Noonan with your personal information. We are
        dedicated to providing you with an exceptional golfing experience while
        respecting your privacy.
      </Typography>
    </Box>
  );
};

export default Trust;

import {
	Grid,
	Typography,
	Box,
	Button,
	useMediaQuery,
	DialogActions,
} from "@mui/material";
import "../guidelines/guidelines.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DialogTitle from "@mui/material/DialogTitle";
import SimulatorModal from "../simulatormodal/SimulatorModal";
import { useState } from "react";
import DialogContentText from "@mui/material/DialogContentText";
const SimulatorStep1 = ({ step, setStep, handlePrev }) => {
	const isLargeScreen = useMediaQuery("(min-width: 900px)");
	const isSmallScreen = useMediaQuery("(max-width: 600px)");

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const btn1 = (
		<Button
			className="guidelines_btn_next"
			onClick={() => setStep(step + 1)}
			startIcon={isLargeScreen ? <ArrowBackIcon /> : null}
			endIcon={isLargeScreen ? <ArrowForwardIcon /> : null}
			mr="1.5rem"
		>
			Confirm
		</Button>
	);
	const btn2 = (
		<Button
			className="guidelines_btn_next"
			sx={{
				backgroundColor: "transparent !important",
				color: "#00ABAB !important",
			}}
			onClick={handleClose}
			autoFocus
			startIcon={isLargeScreen ? <ArrowBackIcon /> : null}
			endIcon={isLargeScreen ? <ArrowForwardIcon /> : null}
		>
			Cancel
		</Button>
	);
	const dialogContent = (
		<Grid
			container
			display="flex"
			flexDirection={{ xs: "column-reverse", md: "row" }}
			alignItems="center"
		>
			<Grid
				item
				xs={12}
				md={9}
				direction="column"
				alignItems={{ xs: "center", md: "flex-start" }}
				textAlign="left"
			>
				<DialogTitle
					className="quick_start"
					mb="2.5rem"
				>
					STOP! Confirm before you move on
				</DialogTitle>
				<DialogContentText className="modalSubtitle1">
					<Typography className="modalSubtitle2">
						You MUST capture the following data points for each shot
					</Typography>
				</DialogContentText>
				<DialogContentText
					className="modalListStyle"
					mb="5rem"
				>
					<ol>
						<li>Carry Distance</li>
						<li>Total Distance</li>
						<li>Offline (how far left/right of the target line)</li>
						<ol type="a">
							<li>Also called carry side, side etc.</li>
						</ol>
					</ol>
				</DialogContentText>
			</Grid>
			<Grid
				item
				xs={12}
				md={3}
				display="flex"
				justifyContent="center"
			>
				<img
					src="/assets/img/guidelines/error_1x.webp"
					alt="guidelines_img"
					className="guidelines_img_s1"
				/>
			</Grid>
		</Grid>
	);

	const dialogActions = (
		<DialogActions className="modalbtnAlign">
			{btn1}
			{btn2}
		</DialogActions>
	);
	return (
		<Box className="container">
			{isSmallScreen ? null : (
				<Button
					startIcon={<ArrowBackIcon />}
					className="backBtn"
					onClick={() => handlePrev(setStep - 1)}
				>
					Back
				</Button>
			)}
			<Box sx={{ display: "flex", gap: 2 }}>
				<Box
					sx={{ display: { md: "none", sm: "none", cursor: "pointer" } }}
					onClick={() => handlePrev(setStep - 1)}
				>
					<ArrowBackIcon style={{ color: "#00abab" }} />
				</Box>
				<Box>
					<Typography
						className="getting_started"
						mb="1.5rem"
						sx={{ display: { md: "block" } }}
					>
						Getting Started
					</Typography>
				</Box>
			</Box>
			<Typography
				className="quick_start"
				mb="3.5rem"
			>
				Step 1 - <span>Simulator Setup</span>
			</Typography>
			<Grid
				container
				sx={{
					display: "flex",
					gap: { xs: "3rem", md: "0rem" },
				}}
			>
				<Grid
					item
					xs={12}
				>
					<Typography
						className="hit_heading"
						mb="1rem"
						sx={{
							textAlign: { xs: "center", sm: "left" },
						}}
					>
						Data points to capture from Launch Monitor/Simulator
					</Typography>
					<Typography
						className="hit_para"
						mb="3.44rem"
					>
						<ol>
							<li>
								Log into your account or create an account (Recommended if it’s
								option). This will ensure your data is saved and can be accessed
								later
								<ol type="a">
									<li>
										If using Trackman, we recommend downloading their App and
										logging in with the same account
									</li>
								</ol>
							</li>
							<li>Go to the virtual Driving Range mode</li>
							<li>Turn off Wind</li>
							<li>
								Write down your altitude setting - i.e. Kansas City is at 1200
								feet above sea level
							</li>
						</ol>
					</Typography>
				</Grid>
			</Grid>
			<Button
				startIcon={isLargeScreen ? <ArrowBackIcon /> : null}
				endIcon={isLargeScreen ? <ArrowForwardIcon /> : null}
				className="guidelines_btn_next"
				disableRipple
				onClick={handleClickOpen}
			>
				Next
			</Button>

			<SimulatorModal
				content={dialogContent}
				open={open}
				handleClose={handleClose}
				dialogActions={dialogActions}
			/>
		</Box>
	);
};

export default SimulatorStep1;

import Accordion from "react-bootstrap/Accordion";

function Accordian() {
  return (
    <div className="container mt-sec mb-sec">
      <div className="row">
        <div className="col-12">
          <Accordion alwaysOpen className="accordian_faq">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                How do I download and install Noonan on my mobile device?
              </Accordion.Header>
              <Accordion.Body>
                <p>To download and install Noonan, follow these steps</p>
                <ul>
                  <li>
                    For iOS devices: Open the App Store, search for "Noonan,"
                    and tap the "Get" button to download and install the app.
                  </li>
                  <li>
                    For Android devices: Open the Google Play Store, search for
                    "Noonan," and tap the "Install" button to download and
                    install the app.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Can Noonan track my scores for each hole during a round of golf?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Yes, Noonan has a score tracking feature that allows you to
                  record your scores for each hole during a round of golf. You
                  can easily enter your scores as you play and keep track of
                  your performance hole by hole.
                </p>
              </Accordion.Body>
            </Accordion.Item>
         
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Can I use Noonan to view and navigate golf course maps with GPS
                functionality?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Yes, Noonan incorporates GPS functionality that enables you to
                  view and navigate golf course maps. The app provides accurate
                  course mapping, displays distances to hazards and greens, and
                  helps you plan your shots strategically based on real-time GPS
                  data.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Does Noonan provide performance analysis and statistics to help
                improve my game?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Certainly! Noonan offers comprehensive performance analysis
                  and statistics to help you improve your game. You can access
                  detailed insights into your scoring average, fairways hit,
                  greens in regulation, putting stats, and other key metrics.
                  These analytics assist in identifying strengths and areas for
                  improvement, allowing you to make data-driven adjustments to
                  enhance your golfing performance.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Accordian;

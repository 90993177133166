import { Grid, Typography, Avatar, Box } from "@mui/material";

const PremierPartner = () => {
  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: "#00ABAB",
        p: { xs: "2rem 1rem", md: "1rem 6rem " },
      }}
    >
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems='center'
        sx={{ gap: { xs: "1rem", md: "0rem" } }}
      >
        {/* Desktop */}
        <Grid item display={{ xs: "none", md: "flex" }} md={3.7}>
          <Typography
            fontWeight="500"
            sx={{
              color: "#fff",
              fontSize: { md: "1.5rem", xl: "3rem" },
            }}
          >
            Become a <br />
            Premier
            <br />
            Partner
            <span style={{ position: "relative" }}>
              Today
              <img
                src="./assets/img/partners/bag.svg"
                alt="bag"
                className="bag_img"
                // style={{
                //   position: "absolute",
                //   bottom: "-18px",
                //   left: "14px",
                //   width: "9.57619rem",
                //   height: "8.14425rem",
                // }}
              />
            </span>
          </Typography>
        </Grid>

        {/* Mobile */}
        <Grid
          item
          xs={12}
          display={{ xs: "flex", md: "none" }}
          alignItems="center"
          position={{ xs: "static" }}
          flexDirection={{ xs: "column" }}
        >
          <Typography
            fontWeight="500"
            sx={{
              color: "#fff",
              fontSize: "1.5rem",
              position: { xs: "static", md: "relative" },
              display: "inline",
              marginRight: "0.5rem",
            }}
          >
            Become a Premier
            <br />
            Partner Today
          </Typography>
          <Avatar
            variant="square"
            src="./assets/img/partners/bag.svg"
            alt="bag"
            sx={{
              width: { xs: "60%" },
              height: { xs: "60%" },
              position: { xs: "static" },
            }}
          />
        </Grid>

        <Grid item xs={12} md={3} className="premier_card">
          <Typography
            fontWeight="bold"
            sx={{
              color: "#fff",
              fontSize: { xs: "1.5rem", lg: "2.5rem" },
            }}
          >
            50%
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: { xs: ".8rem", lg: "1.2rem" },
              opacity: ".7",
            }}
          >
            Margin on <br /> subscriptions
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} className="premier_card">
          <Typography
            fontWeight="bold"
            sx={{
              color: "#fff",
              fontSize: { xs: "1.5rem", lg: "2.5rem" },
            }}
          >
            Free
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: { xs: ".8rem", lg: "1.2rem" },
              opacity: ".7",
            }}
          >
            In-App <br /> adds
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} className="premier_card">
          <Typography
            fontWeight="bold"
            sx={{
              color: "#fff",
              fontSize: { xs: "1.5rem", lg: "2.5rem" },
            }}
          >
            $
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: { xs: ".8rem", lg: "1.2rem" },
              opacity: ".7",
            }}
          >
            Upsell <br /> Bookings
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PremierPartner;

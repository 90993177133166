import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ my: "8rem", height: { md: "90vh" } }}
    >
      <Box
        width={{ xs: "90%", md: "80%" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <img src="./assets/img/404.png" alt="4o4" className="error_img" />
        <Box sx={{ textAlign: "center" }}>
          <Typography className="error_heading">
            Error 404 : Page not found
          </Typography>
          <Typography className="error_message">
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </Typography>
          <Link to="/">
            <Button className="error-btn">Go Back</Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorPage;

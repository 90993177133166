import { Grid, Typography, Box, Button, useMediaQuery } from "@mui/material";
import "./guidelines.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ApplyForm from "../about/ApplyForm";
import { useState } from "react";
import Simulatorstep1 from "../simulatorstep1";
import SimulatorStep2 from "../simulatorstep2/SimulatorStep2";
import SimulatorStep3 from "../SimulatorStep3/SimulatorStep3";

const Guidelines = () => {
	const isLargeScreen = useMediaQuery("(min-width: 900px)");
	const [step, setStep] = useState(0);

	const handleNext = () => {
		setStep(step + 1);
	};

	const handlePrev = () => {
		setStep(step - 1);
	};

	return (
		<Box sx={{ py: { xs: "1rem", md: "2rem" }, mt: "8rem" }}>
			{step == 1 ? (
				<Simulatorstep1
					step={step}
					handleNext={handleNext}
					handlePrev={handlePrev}
					setStep={setStep}
				/>
			) : step == 2 ? (
				<SimulatorStep2
					step={step}
					handleNext={handleNext}
					handlePrev={handlePrev}
					setStep={setStep}
				/>
			) : step == 3 ? (
				<SimulatorStep3
					step={step}
					handleNext={handleNext}
					handlePrev={handlePrev}
					setStep={setStep}
				/>
			) : (
				<Box className="container">
					<Typography
						className="getting_started"
						mb=".2rem"
					>
						Getting Started
					</Typography>
					<Typography
						className="quick_start"
						mb="1rem"
					>
						Quick start Guide -<span> Capturing your shot data</span>
					</Typography>
					<Grid
						container
						sx={{
							display: "flex",
							gap: { xs: "3rem", md: "0rem" },
						}}
					>
						<Grid
							item
							xs={12}
							md={7}
						>
							<Typography className="hit_heading">
								Hit at least 10 shots with each of your clubs
							</Typography>
							<Typography className="hit_para">
								This will allow you to create your initial dispersion pattern
								for each of your clubs. As you continue uploading more shot data
								into Noonan, your dispersion patterns will continually update,
								ensuring that your on-course recommendations are evolving with
								your game.
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={5}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<img
								src="/assets/img/guidelines/guidelines.webp"
								alt="guidelines_img"
								className="guidelines_img"
							/>
						</Grid>
					</Grid>
					<Button
						startIcon={isLargeScreen ? <ArrowBackIcon /> : null}
						endIcon={isLargeScreen ? <ArrowForwardIcon /> : null}
						className="guidelines_btn"
						disableRipple
						onClick={handleNext}
					>
						Get Started
					</Button>
				</Box>
			)}
			<ApplyForm />
		</Box>
	);
};

export default Guidelines;


const OurTeam = () => {
  // const [index, setIndex] = useState(0);

  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };
  return (
    <div
      className="mt-sec bg-about-slider"
      style={{ background: "rgba(210, 227, 227, 0.4)" }}
    >
      <div className="container">
        <h2 className="intro-head pt-5 mb-5">Our Team</h2>
        {/* <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          className="noonan-w-slider pt-1"
        > */}
        {/* <Carousel.Item> */}
        <div className="row">
          <div className="col-lg-3 col-6">
            <div className="team-slider-text">
              <img src="/assets/img/about/matt-ceo.jpg" alt="ourteam-img" />
              <h3>Matt Williams</h3>
              <p>CEO</p>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="team-slider-text">
              <img src="/assets/img/about/alex-cfo.jpg" alt="ourteam-img" />
              <h3>Alex Reed</h3>
              <p>CFO</p>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="team-slider-text">
              <img src="/assets/img/about/sam-cro.jpg" alt="ourteam-img" />
              <h3>Sam Collins</h3>
              <p>CRO</p>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="team-slider-text">
              <img src="/assets/img/about/paul-cto.jpg" alt="ourteam-img" />
              <h3>Paul Buonopane</h3>
              <p>CTO</p>
            </div>
          </div>
        </div>
        {/* </Carousel.Item> */}
        {/* </Carousel> */}
      </div>
    </div>
  );
};

export default OurTeam;

import React from "react";
import Banner from "./Banner";
import RangeFinder from "./RangeFinder";
import IntroNoonan from "./IntroNoonan";
import Advantage from "./Advantage";
import OurStory from "./OurStory";
import KeyFeature from "./KeyFeature";

const Home = () => {
  return (
    <>
      <Banner />
      <KeyFeature />
      <RangeFinder />
      <IntroNoonan />
      <Advantage />
      {/* <SatisfiedCustomers /> */}
      <OurStory />
    </>
  );
};

export default Home;

import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const Header = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const isCurrentlyScrolled = scrollTop > 0;

      setIsScrolled(isCurrentlyScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor:
          location.pathname === "/"
            ? isScrolled
              ? "black"
              : "transparent"
            : "black",
        boxShadow: "none",
        padding: { xs: "0rem .7rem", md: ".2rem 2rem", xl: ".5rem 3rem" },
      }}
    >
      <Toolbar>
        <Box
          width="100%"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1" }}>
            <Link to="/">
              <img
                src="/assets/img/logo.png"
                alt="logo"
                style={{ width: "11rem" }}
              />
              {/* <Avatar
                src="./assets/img/logo-shap.svg"
                alt="logo"
                variant="square"
                style={{ width: "7rem", height: '59px', position: 'absolute', top: '0', opacity: '0.1' }}
              /> */}
            </Link>
          </Box>

          <Box>
            <Stack
              direction="row"
              display="flex"
              // justifyContent="flex-end"
              alignItems="center"
            >
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <IconButton
                  aria-controls="menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  color="inherit"
                >
                  <Avatar
                    src="/assets/img/menu_icon.svg"
                    variant="square"
                    sx={{ width: "1rem", height: "1rem" }}
                  />
                </IconButton>
                <Menu
                  id="menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}>
                    <Link
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: location.pathname === "/" ? "#00ABAB" : "#000",
                      }}
                    >
                      Home
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleMenuClose}>
                    <Link
                      to="/about"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname === "/about" ? "#00ABAB" : "#000",
                      }}
                    >
                      About
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link
                      to="/products"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname === "/products"
                            ? "#00ABAB"
                            : "#000",
                      }}
                    >
                      Products
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link
                      to="/partners"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname === "/partners"
                            ? "#00ABAB"
                            : "#000",
                      }}
                    >
                      Partners
                    </Link>
                  </MenuItem>


                  <MenuItem onClick={handleMenuClose}>
                    <Link
                      to="/faqs"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname === "/faqs" ? "#00ABAB" : "#000",
                      }}
                    >
                      FAQs
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link
                      to="/guidelines"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname === "/guidelines" ? "#00ABAB" : "#000",
                      }}
                    >
                      Guidelines
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link
                      to="/blogs"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname === "/blogs" ? "#00ABAB" : "#000",
                      }}
                    >
                      Blogs
                    </Link>
                  </MenuItem>
                </Menu>
              </Box>
              <Box sx={{ display: { xs: "none", md: "flex" }, gap: "1rem" }}>
                <Link to="/">
                  <Button
                    className="btn"
                    sx={{
                      color: location.pathname === "/" ? "#00ABAB" : "#ffff",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      padding: "6px 20px",
                      "&:hover": { color: "#00ABAB" },
                    }}
                  >
                    Home
                  </Button>
                </Link>
                <Link to="/about">
                  <Button
                    className={
                      (location.pathname === "/" && !isScrolled) && "color_black"
                    }
                    sx={{
                      color:
                        location.pathname === "/about" ? "#00ABAB" : "#ffff",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      padding: "6px 20px",
                      "&:hover": { color: "#00ABAB" },
                    }}
                  >
                    About
                  </Button>
                </Link>
                <Link to="/products">
                  <Button
                    className={
                      (location.pathname === "/" && !isScrolled) && "color_black"
                    }
                    sx={{
                      color:
                        location.pathname === "/products" ? "#00ABAB" : "#ffff",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      padding: "6px 20px",
                      "&:hover": { color: "#00ABAB" },
                    }}
                  >
                    Products
                  </Button>
                </Link>
                <Link to="/partners">
                  <Button
                    className={
                      (location.pathname === "/" && !isScrolled) && "color_black"
                    }
                    sx={{
                      color:
                        location.pathname === "/partners" ? "#00ABAB" : "#ffff",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      padding: "6px 20px",
                      "&:hover": { color: "#00ABAB" },
                    }}
                  >
                    Partners
                  </Button>
                </Link>


                <Link to="/faqs">
                  <Button
                    className={
                      (location.pathname === "/" && !isScrolled) && "color_black"
                    }
                    sx={{
                      color:
                        location.pathname === "/faqs" ? "#00ABAB" : "#ffff",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      padding: "6px 20px",
                      "&:hover": { color: "#00ABAB" },
                    }}
                  >
                    FAQs
                  </Button>
                </Link>
                <Link to="/guidelines">
                  <Button
                    className={
                      (location.pathname === "/" && !isScrolled) && "color_black"
                    }
                    sx={{
                      color:
                        location.pathname === "/guidelines" ? "#00ABAB" : "#ffff",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      padding: "6px 20px",
                      "&:hover": { color: "#00ABAB" },
                    }}
                  >
                    Guidelines
                  </Button>
                </Link>

                <Link to="/blogs">
                  <Button
                    className={
                      (location.pathname === "/" && !isScrolled) && "color_black"
                    }
                    sx={{
                      color:
                        location.pathname === "/blogs" ? "#00ABAB" : "#ffff",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      padding: "6px 20px",
                      "&:hover": { color: "#00ABAB" },
                    }}
                  >
                    Blogs
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
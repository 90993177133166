import React from "react";

const OurStory = () => {
  return (
    <div className="our-story-sec ">
      <div className="container story-box">
        <h2>Our Story</h2>
        <p>
          We are on a mission to simplify the game of golf. <br /><br />
          Arnold Palmer once said, "Golf is deceptively simple and endlessly complex...it is without a doubt the greatest game mankind has ever invented." We couldn't agree more. However, the complexity part is also what leads millions of people to get frustrated and quit every single year. It takes years, if not decades, for golfers to learn to play this game correctly. <br /><br /> Most golfers don't have the patience for that.<br /> <br /> We built Noonan to help golfers of all skill levels navigate the course like a Tour Professional - without the steep learning curve. By simply making better decisions about what clubs to hit and where to aim, players can immediately shoot better scores and enjoy the game we love.
        </p>
      </div>
    </div>
  );
};

export default OurStory;

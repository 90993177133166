import {
	Grid,
	Typography,
	Box,
	Button,
	useMediaQuery,
	MenuItem,
	FormControl,
	Select,
} from "@mui/material";
import "../guidelines/guidelines.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "@mui/material";
import { useState } from "react";

const SimulatorStep3 = ({ step, setStep, handlePrev }) => {
	const isSmallScreen = useMediaQuery("(max-width: 600px)");

	const [value, setValue] = useState("Select Your Simulator");

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	const forSeight = (
		<>
			<Typography
				className="quick_start"
				mt="3.44rem"
				mb="1.25rem"
			>
				Forseight
			</Typography>
			<Typography className="hit_para">
				<ul>
					<li>
						Click the {"Ball Flight"} icon to go to table view to review
						shot/club data
					</li>
					<li>
						You can use the "disable" and "delete" checkboxes to weed through
						your shots, removing those you don't want to export
					</li>
					<li>Click the "Export" button near the top right of the screen</li>

					<li>
						Choose Basic, Advanced, or Custom report
						<ul>
							<li>
								Note: whichever report you choose needs to include Carry, Total,
								and side carry (left/right)
							</li>
						</ul>
					</li>

					<li>
						Select data output type:
						<ol>
							<li>.CSV Human = sidespin expressed with L & R</li>
							<li>.CSV Numeric = sidespin expressed as -/+</li>
							<li>PDF</li>
						</ol>
					</li>
					<li>
						Email the report to yourself or save the file to your computer
					</li>
				</ul>
			</Typography>
		</>
	);

	const garminR = (
		<>
			<Typography
				className="quick_start"
				mt="3.44rem"
				mb="1.25rem"
			>
				Garmin R10
			</Typography>
			<Typography className="hit_para">
				<ul>
					<li>
						<Link
							href="https://support.garmin.com/en-US/?faq=pit4ClEw6f019Cbs3Uhw59"
							style={{ color: "#00ABAB", cursor: "pointer" }}
						>
							Click Here
						</Link>{" "}
						for steps
					</li>
				</ul>
			</Typography>
		</>
	);

	const uneekor = (
		<>
			<Typography
				className="quick_start"
				mt="3.44rem"
				mb="1.25rem"
			>
				Uneekor
			</Typography>
			<Typography className="hit_para">
				<ul>
					<li>
						Click "CSV" button shown in screenshot below and save the file
					</li>
				</ul>
			</Typography>
			<Grid container>
				<Grid
					item
					xs={12}
					sm={8}
					md={4}
					lg={4}
					xl={4}
				>
					<Box>
						<img
							src="/assets/img/guidelines/image_14.webp"
							alt="guidelines_img"
							className="guidelines_img_s3"
							style={{ width: "100%" }}
						/>
					</Box>
				</Grid>
			</Grid>
		</>
	);

	const showDropdowncontent = () => {
		switch (value) {
			case "Forseight":
				return forSeight;
			case "Garmin R10":
				return garminR;
			case "Uneekor":
				return uneekor;
			default:
				return null;
		}
	};

	return (
		<Box className="container">
			{isSmallScreen ? null : (
				<Button
					startIcon={<ArrowBackIcon />}
					className="backBtn"
					onClick={() => handlePrev(setStep - 1)}
				>
					Back
				</Button>
			)}

			<Box sx={{ display: "flex", gap: 2 }}>
				<Box
					sx={{ display: { md: "none", sm: "none", cursor: "pointer" } }}
					onClick={() => handlePrev(setStep - 1)}
				>
					<ArrowBackIcon style={{ color: "#00abab" }} />
				</Box>
				<Box>
					<Typography
						className="getting_started"
						mb="1.5rem"
						sx={{ display: { md: "block" } }}
					>
						Getting Started
					</Typography>
				</Box>
			</Box>
			<Typography
				className="quick_start"
				mb="3.4rem"
			>
				Step 3 - <span>Exporting Data from different simulators</span>
			</Typography>
			<Grid
				container
				sx={{ display: "flex", gap: { xs: "3rem", md: "0rem" } }}
			>
				<Grid
					item
					xs={12}
					md={12}
				>
					<Typography
						className="hit_heading"
						mb="1rem"
					>
						Choose the simulator you’re using below to get specific
						instructions:
					</Typography>
					<Box
						sx={{ minWidth: 120 }}
						className="select_form"
					>
						<FormControl className="formStyle">
							<Select
								value={value}
								onChange={handleChange}
								displayEmpty
								renderValue={(selected) =>
									selected.length === 0 ? "Select Your Simulator" : selected
								}
							>
								<MenuItem value={"Forseight"}>Forseight</MenuItem>
								<MenuItem value={"Garmin R10"}>Garmin R10</MenuItem>
								<MenuItem value={"Uneekor"}>Uneekor</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</Grid>
			</Grid>
			<Box>{showDropdowncontent()}</Box>
		</Box>
	);
};

export default SimulatorStep3;

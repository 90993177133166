import { useEffect, useState } from "react";
import { Typography, Grid, Divider, Chip, Box } from "@mui/material";
import BlogCard from "./BlogCard";

const getTrendingBlogs = (blogs) => {
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const oneMonthAgoDate = oneMonthAgo.toISOString().split('T')[0];

  const trendingScores = blogs.map((blog) => {
    const { viewsHistory = [] } = blog;
    const currentViews = blog.blog_views;

    const oneMonthAgoViews = (viewsHistory || [])
      .filter((entry) => entry.date <= oneMonthAgoDate)
      .reduce((acc, entry) => acc + entry.views, 0);

    const trendingScore = currentViews - oneMonthAgoViews;
    return { ...blog, trendingScore };
  });

  trendingScores.sort((a, b) => b.trendingScore - a.trendingScore);

  return trendingScores;
};

const getPopularBlogs = (blogs) => {
  return blogs
    .filter((blog) => blog.blog_views > 0)
    .sort((a, b) => b.blog_views - a.blog_views);
};

const LatestBlogs = ({ blogs }) => {
  const uniqueCategories = ["All Blogs", "Recent", "Trending", "Popular"];

  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All Blogs");

  useEffect(() => {
    console.log(blogs)
      const sortedBlogs = blogs.sort(
        (a, b) => new Date(b._createdAt) - new Date(a._createdAt)
      );
      setFilteredBlogs(sortedBlogs);

  }, [blogs]);

  const handleFilterBlogs = (category) => {
    setActiveCategory(category);
    switch (category) {
      case "All Blogs":
        setFilteredBlogs(blogs);
        break;

      case "Recent":
        setFilteredBlogs(blogs.slice(0, 5));
        break;

      case "Trending":
        const trendingBlogs = getTrendingBlogs(blogs);
        setFilteredBlogs(trendingBlogs);
        break;

      case "Popular":
        const popularBlogs = getPopularBlogs(blogs);
        setFilteredBlogs(popularBlogs);
        break;

      default:
        setFilteredBlogs(blogs);
        break;
    }
  };

  
  return (
    <>
   
    <Grid container spacing={2} my={{ md: "1rem" }}>
    <Grid item xs={12} md={12} lg={12} xl={12}>
      <Typography className="latest_blog">{activeCategory == "All Blogs" ? activeCategory : `${activeCategory} Blogs`}</Typography>
    </Grid>
    <Grid item md={9} order={{ md: 1, xs: 2 }}>
      <Box>
        {filteredBlogs?.map((blog, index) => (
          <BlogCard key={index} cardDetails={blog} />
        ))}
      </Box>
    </Grid>
    <Grid item md={3} order={{ md: 2, xs: 1 }}>
      <Box className="sticky_sidebar">
        <Typography className="categories">Categories</Typography>
        <Divider
          sx={{
            my: { xs: 1, md: 2 },
            backgroundColor: "rgba(0, 0, 0, 0.10)",
          }}
        />
        <Box
          display="flex"
          justifyContent={{ xs: "center", md: "flex-start" }}
          flexWrap="wrap"
          gap=".3rem"
        >
          {uniqueCategories?.map((category, index) => (
            <Chip
              variant="outlined"
              onClick={() => handleFilterBlogs(category)}
              label={category}
              sx={activeCategory === category ? activeCatStyle : chipStyles}
              disableRipple
              key={index}
            />
          ))}
        </Box>
      </Box>
    </Grid>
  </Grid>
  </>
  );
};

export default LatestBlogs;

const chipStyles = {
  py: { xs: 0.5, md: 1.5 },
  px: 0.5,
  border: "2px solid #00ABAB",
  cursor: "pointer",
  fontSize: { xs: ".875rem", lg: "1rem" },
  fontWeight: 400,
  color: "#00ABAB !important",
  "&:hover": {
    color: "#fff !important",
    backgroundColor: "#00ABAB !important",
  },
};

const activeCatStyle = {
  backgroundColor: "#00ABAB !important",
  color: "#fff !important",
  cursor: "pointer",
  fontSize: { xs: ".875rem", lg: "1rem" },
  fontWeight: 400,
  py: { xs: 0.5, md: 1.5 },
  px: 0.5,
};

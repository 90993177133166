import client from "../../sanityClient";

export const fetchBlogs = async () => {
  const query = `*[_type == "blogs" && !(_id in path("drafts.**"))] {
    _id,
    blog_title,
    "banner_image": banner_image.asset->url,
    content,
    _createdAt,
    blog_views,
    viewsHistory,
    opening_paragraph,
  }`;

  try {
    const blogs = await client.fetch(query);
    
    return blogs.filter(blog => !blog._id.startsWith("drafts."));
  } catch (error) {
    console.error("Error fetching blogs:", error);
    return [];
  }
};



import PartnerBanner from './PartnerBanner';
import { Box } from '@mui/material';
import PremierPartner from './PremierPartner';
import CustomerBenefit from './CustomerBenefit';
import Benefits from './Benefits';
import './partners.css';
import CaseStudy from './CaseStudy';


const Partners = () => {
 
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <PartnerBanner />
      <PremierPartner />
      <CustomerBenefit />
      <Benefits />
      <CaseStudy />
    </Box>
  );
};

export default Partners;
